import React from 'react'
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { EventChannelApi } from '../api/entities';
import BasePageList from './base_list';


function EventChannelList() {
    const { t } = useTranslation();
    const context = useOutletContext();

    return <BasePageList
                name={t('pageList.eventchannel', {count: 2})}
                api={EventChannelApi}
                tableHeaders = {[t('pageList.name'), t('pageList.service', {count: 1})]}
                getTableRow={(row) => ([row.name, row.provider?.name])}
                canImport={true}
                canExport={true}
                canChange={context.me.has_permission('CalendarProvider.Add')}
            />;
}

export default EventChannelList;
