import React from 'react'
import { Formik } from 'formik';
import { Container, Card, Col, Row, Form, Alert, Image, Button } from 'react-bootstrap';
import { Navigate, useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import * as yup from 'yup';

import { SpinningButton } from '../components';
import { UserApi } from '../api/entities';
import './login.css';
import { lineSplit } from '../helpers';
import { FormSubmitError } from '../components/exceptions';


function ForgotPassword() {
    const { t } = useTranslation();

    const schema = yup.object().shape({
        email: yup.string().email(t('pageForgotPassword.emailInvalid')).required(t('pageForgotPassword.emailRequired'))
    });

    const [submitted, setSubmitted] = React.useState(false);

    function ForgotPasswordForm() {
        return (
            <Formik
                initialValues={{ email: '' }}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting, setStatus, setErrors }) => {
                    try {
                        await UserApi.reset_password(values.email);
                        setSubmitted(true);
                    }
                    catch(err) {
                        if(err.name === 'ApiError' && err.errors && Object.keys(err.errors).length)
                            setErrors({
                                'email' : err.errors.Email,
                            });
                        else
                            setErrors({
                                'general' : err.message,
                            });
                    }
                }}
            >
            {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, isSubmitting, errors, status }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="ForgotPasswordFormUser">
                        <Form.Label><Trans i18nKey="pageForgotPassword.email">Email</Trans></Form.Label>
                        <Form.Control type="text" name="email" onChange={handleChange} value={values.email} isInvalid={touched.email && !!errors.email}/>
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>
    
                    {status && <Alert variant="danger">{status}</Alert>}
                    <div className="d-grid gap-2">
                        <SpinningButton className="fontsize" variant="primary" type="submit" isSubmitting={isSubmitting}>
                            <Trans i18nKey="pageForgotPassword.sendResetEmail">Send password reset email</Trans>
                        </SpinningButton>
                    </div>
                    { errors.general &&
                        <Row>
                            <Col className="text-center mb-4">
                                <p className="text-danger">
                                    {lineSplit(errors.general)}
                                </p>
                            </Col>
                        </Row>
                    }
                </Form>
            )}
            </Formik>
        )
    }



    var reset_form = (
        <Container fluid className="h-100">
            <Row className="justify-content-center align-items-center h-100 pb-5">
                <Col sm={8} md={6} lg={4} xl={3}>
                    <Card>
                        <Card.Img id="logo" className="py-3 px-5" src={require('../assets/logo.png')} />
                        <Card.Body>
                            <div className="d-grid gap-2">
                                <p className='text-center px-2'>
                                    <Trans i18nKey="pageForgotPassword.sendResetEmailText">
                                        Please enter your email below and a password reset email will be sent to you.
                                        Follow the instructions in the email to reset your password
                                    </Trans>
                                </p>
                            </div>
                            <ForgotPasswordForm />
                            <p className='text-center px-2'>
                                <Trans i18nKey="pageForgotPassword.alreadyHaveAccount">Already have an account?</Trans>
                                <Button className="fontsize" variant="link" as={Link} to="/login">
                                    <Trans i18nKey="pageForgotPassword.loginHere">Login here</Trans>
                                </Button>
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );

    var reset_done = (
        <Container fluid className="h-100">
            <Row className="justify-content-center align-items-center h-100 pb-5">
                <Col sm={8} md={6} lg={4} xl={3}>
                    <Card>
                        <Card.Img id="logo" className="py-3 px-5" src={require('../assets/logo.png')} />
                        <Card.Body>
                            <div className="d-grid gap-2">
                                <p className='text-center px-2'>
                                    <Trans i18nKey="pageForgotPassword.sendResetEmailCompleteText">
                                        Your request has been processed. If you have an active account,
                                        you will receive an email in the address you have provided
                                    </Trans>
                                </p>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );

    return submitted ? reset_done : reset_form;
}

export default ForgotPassword;
