
function EventDateValidator(value, context)
{
    var originalValue = null;
    try {
        originalValue = new Date(context.options.context.initialValues[context.path]);
        originalValue = new Date(originalValue);
    }
    catch {
        console.error('Invalid context: ', context.options.context);
    }
    
    var newValue = new Date(value);

    if(newValue.getTime() == originalValue.getTime())
        return true;
    // Has change in value but new value is later than now
    else if(newValue.getTime() > (new Date()).getTime())
        return true;

    return false;
}

export default EventDateValidator;