import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSearchParams, useOutletContext } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import { Calendar, Views, Navigate, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/el';
import { i18n } from '../i18n';

import "react-big-calendar/lib/css/react-big-calendar.css";
import Select from 'react-select';
import { toast } from 'react-toastify';

import { AppSpinnerOverlay, AppointmentForm } from '../components';
import { Event } from '../models';

import { BranchApi, UserApi } from '../api/entities';
import apiFactory from '../api/api_factory';
import { lineSplit } from '../helpers';


function UserCalendar(props) {
    const { t } = useTranslation();
    const context = useOutletContext();

    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries(searchParams.entries());

    const defaultSelectOption = {"label": `- ${t('pageList.all')} -`, "value": ""};

    const [userApi] = React.useState(apiFactory.get_instance(UserApi));
    const [branchApi] = React.useState(apiFactory.get_instance(BranchApi));

    const [users, setUsers] = React.useState([]);
    const [activeUsers, setActiveUsers] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState(null);

    const [branches, setBranches] = React.useState([]);
    const [selectedBranch, setSelectedBranch] = React.useState(null);

    const [events, setEvents] = React.useState(null);
    const [unavailabilites, setUnavailabilites] = React.useState(null);

    const [showAppointmentForm, setShowAppointmentForm] = React.useState(false);
    const [currentEvent, setCurrentEvent] = React.useState(null);

    const [startDate, setStartDate] = React.useState(null);
    const [endDate, setEndDate] = React.useState(null);

    moment.locale(i18n.language);
    const localizer = momentLocalizer(moment);

    const refreshEvents = () => {
        if(startDate && endDate && (selectedUser || selectedBranch)) {
            setEvents(null);

            var object = null;
            if(selectedUser)
                object = selectedUser;
            else if(selectedBranch)
                object = selectedBranch;

            object.get_events(startDate, endDate).then(e => setEvents(e));
            object.get_all_unavailabilities(startDate, endDate).then(u => setUnavailabilites(u));
        }
    }

    React.useEffect(() => {
        apiFactory.clear_all_cache();
        // We set startDate when userApi is done and endDate when branchApi is done
        // this is a hack so that refresh is triggered only when both are done
        // so that we don't do a double refresh
        userApi.get_objects().then(x => {
            setUsers(x);
            if(currentParams['user'])
                setSelectedUser(x.find(x => x.id === currentParams['user']));
            else
                setSelectedUser(props.user);
            setStartDate(moment().subtract(1, 'weeks').toDate());
        });
        branchApi.get_objects().then(x => {
            setBranches(x);
            if(currentParams['branch'])
                setSelectedBranch(x.find(x => x.id === currentParams['branch']));
            else
                setSelectedBranch(props.user.branch);
            setEndDate(moment().add(1, 'weeks').toDate());
        });
    }, []);


    React.useEffect(() => {
        if(selectedBranch)
            setActiveUsers(users.filter(u => u.branch_id === selectedBranch.id));
        else
            setActiveUsers(users);
    }, [selectedBranch]);


    React.useEffect(() => {
        if(activeUsers && selectedUser) {
            if(!activeUsers.find(u => u.id === selectedUser.id))
                setSelectedUser(null);
        }
    }, [activeUsers]);


    React.useEffect(() => {
        if(users && branches && startDate && endDate) {
            if (selectedBranch == null && selectedUser == null) {
                toast.error(t("pageList.selectUserOrBranch"));
            }
            else {
                refreshEvents();
            }
            
        }


    }, [startDate, endDate, selectedBranch, selectedUser]);

    
    React.useEffect(() => {
        var newEvents = context.notifications.filter(n => n.type === 'NewEvent');
        if(newEvents.length > 0 && events !== null)
        {
            var curr_events = events;
            newEvents.forEach(eventNotification => {
                var event = eventNotification.data;
                curr_events = curr_events.filter(e => e.id !== event.id);
                if(
                    event.cancelled !== true &&
                    (selectedUser == null || event.users?.find(x => x.id == selectedUser.id) != null) &&
                    (selectedBranch == null || event.branch?.id == selectedBranch.id)
                )
                    curr_events.push(event);
            });
            setEvents(curr_events);
        }
    }, [context.notifications]);

    const convertEvt = (evt) => ({
        id: evt.id,
        title: evt.title,
        start: evt.start,
        end: evt.end,
        type: 'event',
        assigned: evt.users?.length > 0 ? true : false
    });

    const convertUnv = (unv) => ({
        id: unv.id,
        title: lineSplit(unv.description),
        start: unv.start,
        end: unv.end,
        type: 'unavailability'
    });


    const eventClashes = (evts, start, end) => {
        var clashes = evts.filter(u =>
            (u.start >= start && u.start < end) ||
            (u.end > start && u.end <= end ) ||
            (u.start <= start && u.end >= end ) ||
            (u.start >= start && u.end <= end)
        );
        return (clashes.length > 0);
    };


    const handleRangeChange = async (rangeInfo) => {
        var start = null;
        var end = null;
        if(rangeInfo.start && rangeInfo.end) {
            start = rangeInfo.start;
            end = rangeInfo.end;
        }
        else {
            start = new Date(rangeInfo[0]);
            end = new Date(rangeInfo.pop());
            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);
        }

        setStartDate(start);
        setEndDate(end);
    };


    const handleNewEvent = React.useCallback(({ start, end }) => {
        // Don't open appointment if it clashes with
        // existing appointments
        if(eventClashes(events, start, end)) {
            toast.error(t("pageList.conflictEventFailure"));
            return;
        }

        // Don't open appointment if it clashes with
        // unavailabilities
        if(eventClashes(unavailabilites, start, end)) {
            toast.error(t("pageList.noAvailabilityEventFailure"));
            return;
        }
            
        // Don't open appointment for past times
        const now = new Date();
        if(start < now || end < now) {
            toast.error(t("pageList.pastEventFailure"));
            return;
        }
            

        var evt = new Event();
        evt.start = start;
        setCurrentEvent(evt);
        setShowAppointmentForm(true);
    });


    const handleEditEvent = React.useCallback((event) => {
        var evt = events.find(e => e.id === event.id);
        setCurrentEvent(evt);
        setShowAppointmentForm(true);
    }, [events]);


    const handleCancel = () => {
        handleClose();
    };

    const handleSucess = () => {
        handleClose();
        refreshEvents(); // Hacky way to refresh the events
    };

    const handleClose = () => {
        setShowAppointmentForm(false);
        setCurrentEvent(null);
    };


    const eventStyleGetter = (event, start, end, isSelected) => {
        var style = {
            fontSize : '80%'
        };

        if(event.type === 'event') {
            if(event.assigned === true)
            {
                style = Object.assign({}, style, {
                    backgroundColor: '#8cff66',
                    color: '#333',
                    border: 'solid 1px #33cc00',
                    borderRadius: '1px'
                });
            }
            else
            {
                style = Object.assign({}, style, {
                    backgroundColor: '#ff9',
                    color: '#333',
                    border: 'solid 1px #fc6',
                    borderRadius: '1px'
                });
            }
        }
        else if(event.type === 'unavailability') {
            style = Object.assign({}, style, {
                backgroundColor: '#00000000',
                backgroundImage: `linear-gradient(45deg, #c5c5c5a0 25%, #d1d1d1a0 25%, #d1d1d1a0 50%, #c5c5c5a0 50%, #c5c5c5a0 75%, #d1d1d1a0 75%)`,
                backgroundSize: '25px 25px',
                borderRadius: '1px',
                borderColor: '#959595',
                display: 'block',
                color: '#333'
            });
        }

        return { style : style };
    }


    const renderCustomToolbar = (tbProps) => {
        var branchoptions = branches.map(x => ({ "label": x.toString(), "value": x.id }));

        if(props.user.branch)
            branchoptions = branchoptions.filter(b => b.value == props.user.branch.id)

        branchoptions.unshift(defaultSelectOption);

        var useroptions = activeUsers.map(x => ({ "label": x.toString(), "value": x.id }));
        useroptions.unshift(defaultSelectOption);

        return (
            <div className="rbc-toolbar fontsize">
                <Col md={2} className='pe-2'>
                    <div style={{ position: 'relative', zIndex: 10 }}>
                        <Select
                            inputId={"branchSelect"}
                            options={branchoptions}
                            placeholder={t('pageList.selectBranch')}
                            value={selectedBranch && {"label": selectedBranch.toString(), "value": selectedBranch.id}}
                            onChange={(option) => {
                                const currentParams = Object.fromEntries(searchParams.entries());
                                const newParams = { ...currentParams, branch: option.value};
                                setSearchParams(newParams);
                                setSelectedBranch(branches.find(x => x.id === option.value));
                            }}
                        ></Select>
                    </div>
                </Col>
                <Col md={2} className='pe-2'>
                    <div style={{ position: 'relative', zIndex: 10 }}>
                        <Select
                            inputId={"userSelect"}
                            options={activeUsers && useroptions}
                            value={selectedUser && {"label": selectedUser.toString(), "value": selectedUser.id}}
                            placeholder={t('pageList.selectUser')}
                            onChange={(option) => {
                                const currentParams = Object.fromEntries(searchParams.entries());
                                const newParams = { ...currentParams, user: option.value};
                                setSearchParams(newParams);
                                setSelectedUser(users.find(x => x.id === option.value));
                            }}
                        ></Select>
                    </div>
                </Col>
    
                <span className="rbc-toolbar-label">
                    <span className="rbc-btn-group">
                        <button type="button" onClick={() => tbProps.onNavigate(Navigate.PREVIOUS)}>&lt;</button>
                        <button type="button" onClick={() => tbProps.onNavigate(Navigate.TODAY)}>{tbProps.label}</button>
                        <button type="button" onClick={() => tbProps.onNavigate(Navigate.NEXT)}>&gt;</button>
                    </span>
                </span>
                <span className="rbc-btn-group">
                    <button type="button" onClick={() => tbProps.onView(Views.MONTH)}><Trans i18nKey="pageList.month">Month</Trans></button>
                    <button type="button" onClick={() => tbProps.onView(Views.WEEK)}><Trans i18nKey="pageList.week">Week</Trans></button>
                    <button type="button" onClick={() => tbProps.onView(Views.DAY)}><Trans i18nKey="pageList.day">Day</Trans></button>
                </span>
            </div>
        );
      };

    return (
        <>
            {currentEvent &&
                <AppointmentForm user={props.user} event={currentEvent} selectedBranch={selectedBranch} selectedUser={selectedUser}
                                 show={showAppointmentForm} onCancel={handleCancel} onSuccess={handleSucess}/>
            }
            <Row>
                <Col>
                    <div className="spinner-container">
                        {(!events || !unavailabilites) &&
                            <AppSpinnerOverlay />
                        }
                        <Calendar
                            step={15}
                            localizer={localizer}
                            defaultView={Views.WEEK}
                            events={(events && unavailabilites)? [
                                ...events.map((x) => convertEvt(x)),
                                ...unavailabilites.map((x) => convertUnv(x))
                            ] : []}
                            eventPropGetter={eventStyleGetter}
                            startAccessor="start"
                            endAccessor="end"
                            onSelectEvent={handleEditEvent}
                            onSelectSlot={handleNewEvent}
                            onRangeChange={handleRangeChange}
                            selectable
                            style={{ height: "90vh" }}
                            components={{
                                toolbar: renderCustomToolbar,
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default UserCalendar;