import { BaseModel }  from './base_model';
import { PermissionApi, RoleApi } from '../api/entities';
import apiFactory from '../api/api_factory';

class Role extends BaseModel {
    #permission_api = apiFactory.get_instance(PermissionApi);
    #role_api = apiFactory.get_instance(RoleApi);


    _get_related_api() {
        return {
            "permissions" : this.#permission_api,
            "roles" : this.#role_api
        }
    }

    async get_active_options() {
        return {
            'permissions': await this.get_related_options('permissions'),
            'roles' : await this.get_related_options('roles')
        }
    }

    async import_from_api(data) {
        this.id = data.id;
        this.name = data.name;
        this.permissions = data.permissions;
        this.role_grants = data.roleGrants;
    };

    export_to_api() {
        const data_object = {
            "name" : this.name,
            "permissions" : this.permissions,
            "roleGrants" : this.role_grants
        }

        if(this.id)
            data_object.id = this.id;

        return data_object;
    }

    async import_from_form(data) {
        this.name = data.name;

        if(!data.permissions)
            this.permissions = [];
        else
            this.permissions = data.permissions;

        if(!data.rolegrants)
            this.role_grants = [];
        else
            this.role_grants = data.rolegrants;
    }

    export_to_form() {
        return {
            'id' : this.id,
            'name' : this.name,
            'permissions' : this.permissions,
            "rolegrants" : this.role_grants
        }
    }

    static errors_to_form(errors) {
        return {
            'id' : errors.Id,
            'name' : errors.Name,
            'permissions' : errors.Permissions,
            'rolegrants' : errors.RoleGrants
        }
    }

    toString() {
        return this.name;
    }
}

export default Role;