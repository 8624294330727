import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddCircle } from 'iconoir-react';

import { Col, Row, Modal } from 'react-bootstrap';
import * as yup from 'yup';
import moment from 'moment';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { VideoCamera } from 'iconoir-react'; 

import apiFactory from '../api/api_factory';
import { UserApi, EventApi, ServiceApi, GuestApi, EventTypeApi, EventChannelApi } from '../api/entities';
import { Event } from '../models';
import { AppForm, AppFormInput, AppSpinner, AppFormCard } from '../components';
import { FormSubmitError } from '../components/exceptions';
import EventDateValidator from './eventdate_validator';


function AppointmentForm(props) {
    const { t } = useTranslation();

    const [users, setUsers] = React.useState([]);
    const [services, setServices] = React.useState([]);
    const [eventChannels, setEventChannels] = React.useState([]);

    const [eventApi] = React.useState(apiFactory.get_instance(EventApi));
    const [userApi] = React.useState(apiFactory.get_instance(UserApi));
    const [serviceApi] = React.useState(apiFactory.get_instance(ServiceApi));
    const [guestApi] = React.useState(apiFactory.get_instance(GuestApi));
    const [eventChannelApi] = React.useState(apiFactory.get_instance(EventChannelApi));

    const [activeOptions, setActiveOptions] = React.useState([]);
    const [currentStep, setCurrentStep] = React.useState(null);
    const [currentMinDatetime, setCurrentMinDatetime] = React.useState(null);

    const [isLoading, setIsloading] = React.useState(true);

    const [isNewGuestForm, setIsNewGuestForm] = React.useState(false);
    const [guestEntity, setGuestEntity] = React.useState(new guestApi.MODEL_CLASS());
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        async function fetchData() {
            setIsloading(true);

            var curr_services = await serviceApi.get_objects();
            setServices(curr_services);

            var curr_channels = await eventChannelApi.get_objects();
            setEventChannels(curr_channels);

            var curr_users = await userApi.get_objects();
            setUsers(curr_users);
        }

        fetchData().catch(err => setError(err));

    }, [userApi, serviceApi, eventChannelApi, props.event]);


    React.useEffect(() => {
        handleChange(null, props.event.export_to_form()).catch(err => setError(err));
        setIsloading(false);
    }, [users]);


    const getInitialValues = () => {
        var values = props.event.export_to_form();
        if(props.event.id == null) {
            if(props.selectedUser)
                values['user'] = props.selectedUser.id;
            if(props.selectedBranch)
                values['branch'] = props.selectedBranch.id;
        }
        return values;
    }

    const BookNewEvent = async (values) => {
        try {
            var post_data = {
                "Title" : values.title,
                "Description" : values.description,
                "Start" : values.start,
                "Users" : values.user ? [values.user] : [],
                "Channel" : values.channel,
                "BranchId" : values.branch,
                "ExistingGuests" : values.guests,
            };
            await serviceApi.Book(values.service, post_data);
        }
        catch(err) {
            var errors = [];
            if(err.name === 'ApiError' && err.errors && Object.keys(err.errors).length)
                errors = {
                    'title' : err.errors.Title,
                    'start' : err.errors.Start,
                    'user' : err.errors.Users,
                    'channel' : err.errors.Channel,
                    'branch' : err.errors.BranchId,
                    'guests' : err.errors.ExistingGuests
                };
            else
                errors['general'] = err.message;
            throw new FormSubmitError(errors);
        }
    }

    const EditEvent = async (values) => {
        try {
            await props.event.import_from_form(values);
            await eventApi.update_object(props.event.id, props.event);
        }
        catch(err) {
            var errors = [];
            if(err.name === 'ApiError' && err.errors && Object.keys(err.errors).length)
                errors = eventApi.MODEL_CLASS.errors_to_form(err.errors);
            else
                errors['general'] = err.message;
            throw new FormSubmitError(errors);
        }
    }

    const handleSubmit = async (values) => {
        if(!props.event.id) {
            return await BookNewEvent(values);
        }
        else {
            return await EditEvent(values);
        }
    }


    const handleChange = async (current_values, next_values) => {
        var form_users = [];
        var form_branches = [];
        var form_channels = [];

        var selected_service = services.find(s => s.id === next_values['service']);
        if(selected_service) {
            form_users = users.filter(user => selected_service.users?.some(serviceUser => serviceUser.id === user.id));
            form_branches = users.filter(user => selected_service.users?.some(serviceUser => serviceUser.id === user.id) && user.branch != null)
                                 .map(user => user.branch);
            // Deduplicate the branches
            form_branches = Array.from(new Set(form_branches.map((b) => b.id)))
                                 .map((id) => form_branches.find((b) => b.id === id)); 

            form_channels = eventChannels.filter(obj => selected_service.eventtype.available_channels.includes(obj.id));
        }
        var selected_branch = form_branches.find(s => s.id === next_values['branch']);
        if(selected_branch)
            form_users = form_users.filter(user => user.branch_id == selected_branch.id);
        else
            form_users = form_users.filter(user => user.branch_id == null);


        var active_options = {
            "services" : services,
            "branches" : form_branches,
            "users" : form_users,
            "channels" : form_channels
        }
        setActiveOptions(active_options);

        next_values.end = selected_service ? moment(next_values.start).add(selected_service.eventtype.duration).toDate() : null;

        // TODO: This doesn't correctly line-up between slots
        setCurrentMinDatetime(moment().startOf("hour"));
        var curstep = selected_service ? selected_service.eventtype.duration.asSeconds() : null;
        setCurrentStep(curstep);

        if(current_values != null)
            await props.event.import_from_form(next_values);
    };

    const handleEventStatusChange = async (option) => {
        var status = option.value;
        try {
            await eventApi.changeStatus(props.event.id, Number(status));
            props.event.status = status;
        }
        catch {
            toast.error(t('pageList.eventStatusUpdateFailure'));
        }
    };

    const handleCancelEvent = async () => {
        props.event.cancelled = true;
        await eventApi.update_object(props.event.id, props.event);
        props.onSuccess();
    }

    const handleGuestSearch = async (inputValue) => {
        var guests = await guestApi.get_objects({'search' : inputValue});
        return guests.map(x=> ({"label" : x.toString(), "value" : x.id}));
    }

    const getGuestInitialValues = () => {
        var values = guestEntity.export_to_form();
        values['organisation'] = props.user.organisation?.id;
        return values;
    }

    const handleGuestSubmit = async (values) => {
        var new_guest_entity = null;
        try {
            await guestEntity.import_from_form(values);
            new_guest_entity = await guestApi.add_object(guestEntity);
        }
        catch(err) {
            var errors = [];
            if(err.name === 'ApiError' && err.errors && Object.keys(err.errors).length)
                errors = guestApi.MODEL_CLASS.errors_to_form(err.errors);
            else
                errors['general'] = err.message;
            throw new FormSubmitError(errors);
        }

        setGuestEntity(new_guest_entity);
        props.event.guests.push(new_guest_entity);
        return new_guest_entity.export_to_form();
    }

    const handleGuestClose = () => {
        setGuestEntity(null);
        setIsNewGuestForm(false);
        setGuestEntity(new guestApi.MODEL_CLASS());
    }

    var status_options = Event.get_statuses().map(x=> (
                            {"label" : <>{x.icon}&nbsp;{x.name}</>, "value" : x.value}
                        ))

    return (
            <Modal size="lg" show={props.show} onHide={() => props.onCancel()}>
                {!isLoading && !isNewGuestForm &&
                    <AppFormCard
                                 onCancel={() => props.onCancel()} canDelete={true}
                                 onDelete={props.event.id && handleCancelEvent}
                                 title={props.event.id ? props.event.title : t('pageList.newEvent')}
                                 name={props.event.title}
                                 customAction={
                                     <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                        {props.event.channelProviderUrl && (
                                         <button
                                             type="button"
                                             className="btn btn-outline-primary"
                                             style={{
                                                 backgroundColor: 'green',
                                                 color: 'white', 
                                                 display: 'flex',
                                                 alignItems: 'center',
                                                 gap: '5px',
                                                 padding: '8px 12px',
                                                 border: 'none', 
                                                 borderRadius: '4px',
                                                 cursor: 'pointer',
                                             }}
                                             onClick={() => window.open(props.event.channelProviderUrl, '_blank')}
                                         >
                                             <VideoCamera width={20} height={20} style={{ color: 'white' }} /> {/* Icon added here */}
                                             {t('')}
                                         </button>
                                        )}
                                        <span className='d-inline-block' style={{ minWidth: '170px' }}>                                            
                                            <Select
                                                defaultValue={status_options.find(s => s.value === props.event.status)}
                                                options={status_options}
                                                onChange={(option) => handleEventStatusChange(option)}
                                                styles={{
                                                    option: (provided) => ({
                                                        ...provided,
                                                        whiteSpace: 'nowrap',
                                                    })
                                                }}
                                            />
                                        </span>
                                     </div>                                 
                                }
                            >
                        <AppForm
                            name='eventForm'
                            canChange={true}
                            values={getInitialValues()}
                            closeButton={true}
                            cancelButton={false}
                            onCancel={() => props.onCancel()}
                            OnSuccess={() => props.onSuccess()}
                            onSubmit={handleSubmit}
                            onChange={handleChange}>
                        
                            <AppFormInput title='Id' name='id' type='hidden' readonly={true} validator={yup.string().nullable()}/>
                                <Row>
                                    <Col>
                                        <AppFormInput title='Booking Number' name='eventNumber' type='text' readonly={true} validator={yup.string().nullable()} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <AppFormInput title={t('pageList.start')} name='start' type='datetime-local' required={true}
                                                      min={currentMinDatetime} step={currentStep}
                                                      validator={yup.date().typeError('Not a valid date').required().test('EventDateValidator', 'Cannot change event to a past date', EventDateValidator)}/>
                                    </Col>
                                    <Col>
                                        <AppFormInput title={t('pageList.end')} name='end' type='datetime-local' readonly={true} required={true}
                                                      min={currentMinDatetime} step={currentStep}
                                                      validator={yup.date().typeError('Not a valid date').required().test('EventDateValidator', 'Cannot change event to a past date', EventDateValidator)}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <AppFormInput title={t('pageList.service', {count : 1})} name='service' type='select' required={true}
                                                      options={activeOptions['services'].map(x=> ({"label" : x.toString(), "value" : x.id}))}
                                                      validator={yup.string().nullable().min(1).required()}/>
                                    </Col>
                                    <Col>
                                        <AppFormInput title={t('pageList.channel')} name='channel' type='select' required={true}
                                                      options={activeOptions['channels'].map(x=> ({"label" : x.toString(), "value" : x.id}))}
                                                      validator={yup.string().nullable().required()}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <AppFormInput title={t('pageList.branch', {count : 1})} name='branch' type='select' required={true}
                                                      options={activeOptions['branches'].map(x=> ({"label" : x.toString(), "value" : x.id}))}
                                                      validator={yup.string().nullable().required()}/>
                                    </Col>
                                    <Col>
                                        <AppFormInput title={t('pageList.user', {count : 1})} name='user' type='select'
                                                      options={activeOptions['users'].map(x=> ({"label" : x.toString(), "value" : x.id}))}
                                                      validator={yup.string().nullable()}/>
                                    </Col>
                                </Row>


                                <AppFormInput title={t('pageList.description')} name='description' type='textarea' required={true}
                                              validator={yup.string().nullable().required()}/>

                                <Row>
                                    <Col>
                                        <AppFormInput title={t('pageList.guest', {count : 2})} name='guests' type='multiselect' required={true}
                                                      action={<AddCircle height={24} width={24} onClick={() => setIsNewGuestForm(true)}/>}
                                                      options={props.event?.guests ? props.event.guests.map(x=> ({"label" : x.toString(), "value" : x.id})) : []}
                                                      loadOptions={handleGuestSearch} validator={yup.array().of(yup.string()).min(1)}/>
                                    </Col>
                                </Row>
                        </AppForm>
                    </AppFormCard>
                }
                {!isLoading && isNewGuestForm &&
                    <AppFormCard onCancel={() => props.onCancel()} canDelete={false} title={t('pageList.newGuest')} name='New guest'>
                        <AppForm
                            name='guestForm'
                            canChange={true}
                            values={getGuestInitialValues()}
                            closeButton={true}
                            cancelButton={false}
                            onCancel={handleGuestClose}
                            OnSuccess={handleGuestClose}
                            onSubmit={handleGuestSubmit}>
                                <AppFormInput title={t('pageList.organisation', {count : 1})} name='organisation' type='hidden'
                                              readonly={true} validator={yup.string().nullable().required()} required={true}/>
                                <Row>
                                    <Col>
                                        <AppFormInput title={t('pageList.firstName')} name='first_name' type='text' required={true}
                                                      validator={yup.string().nullable().required('First name is a required field')}/>
                                    </Col>
                                    <Col>
                                        <AppFormInput title={t('pageList.lastName')} name='last_name' type='text' required={true}
                                                      validator={yup.string().nullable().required('Last name is a required field')}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <AppFormInput title={t('pageList.phoneNumber')} name='phone_number' type='text'
                                                      validator={yup.string().nullable()} required={true}/>
                                    </Col>
                                    <Col>
                                        <AppFormInput title={t('pageList.email')} name='email' type='text'
                                                      validator={yup.string().email().nullable()}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <AppFormInput title={t('pageList.notes')} name='notes' type='textarea'
                                                      validator={yup.string().nullable()}/>
                                    </Col>
                                </Row>
                        </AppForm>
                    </AppFormCard>
                }
                {isLoading &&
                    <AppSpinner/>
                }
            </Modal>
    )
}


export default AppointmentForm;