import { BaseModel }  from './base_model';
import { CommProviderApi, CalendarProviderApi, EventChannelApi } from '../api/entities';
import apiFactory from '../api/api_factory';

class Organisation extends BaseModel {
    #commprovider_api = apiFactory.get_instance(CommProviderApi);
    #calendarprovider_api = apiFactory.get_instance(CalendarProviderApi);
    #eventchannel_api = apiFactory.get_instance(EventChannelApi);

    languages = [
        {
            "name" : "English",
            "value" : "en-US"
        },
        {
            "name" : "Greek",
            "value" : "el-GR"
        }
    ];


    _get_related_api() {
        return {
            "commproviders" : this.#commprovider_api,
            "calendarproviders" : this.#calendarprovider_api,
            "eventchannels" : this.#eventchannel_api
        }
    }

    async get_active_options() {
        return {
            'commproviders': await this.get_related_options('commproviders'),
            'calendarproviders': await this.get_related_options('calendarproviders'),
            'eventchannels': await this.get_related_options('eventchannels')
        }
    }

    async import_from_api(data) {
        this.active = data.active;
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.timezone = data.timezone;
        this.language = this.languages.find(p => p.value === data.language);
        this.commproviders = data.commProviders;
        this.calendarproviders = data.calendarProviders;
        this.eventchannels = data.eventChannels;
    };

    export_to_api() {
        var data_object = {
            "active" : this.active,
            "name" : this.name,
            "description" : this.description,
            "timezone" : this.timezone,
            "language" : this.language.value,
            "commProviders" : this.commproviders,
            "calendarProviders" : this.calendarproviders,
            "eventChannels" : this.eventchannels
        };
        if(this.id)
            data_object.id = this.id;
            
        return data_object;
    }

    async import_from_form(data) {
        this.active = data.active;
        this.name = data.name;
        this.description = data.description;
        this.timezone = data.timezone;
        this.language = this.languages.find(p => p.value === data.language);

        if(data.commproviders)
            this.commproviders = data.commproviders;
        else
            this.commproviders = [];

        if(data.calendarproviders)
            this.calendarproviders = data.calendarproviders;
        else
            this.calendarproviders = [];

        if(data.eventchannels)
            this.eventchannels = data.eventchannels;
        else
            this.eventchannels = [];
    }

    export_to_form() {
        return {
            'id' : this.id,
            'active' : this.active,
            'name' : this.name,
            'description' : this.description,
            'timezone' : this.timezone,
            'language' : this.language?.value,
            'commproviders' : this.commproviders,
            'calendarproviders' : this.calendarproviders,
            'eventchannels' : this.eventchannels,
        }
    }

    static errors_to_form(errors) {
        return {
            'id' : errors.Id,
            'active' : errors.Active,
            'name' : errors.Name,
            'description' : errors.Description,
            'timezone' : errors.Timezone,
            'language' : errors.Language,
            'commproviders' : errors.CommProviders,
            'commproviders' : errors.calendarProviders,
            'eventchannels' : errors.eventChannels
        }
    }

    toString() {
        return this.name;
    }
}

export default Organisation;
