import { BaseModel }  from './base_model';

class Statistics extends BaseModel {

    _process_result(result)
    {
        return result;
    }

    async import_from_api(data) {
        this.entity = data.entity;
        this.aggregate = data.aggregateBy;
        this.filters = data.filters;
        this.result = this._process_result(data.result);
    };

    export_to_api() {
        return {};
    }
}


export default Statistics;
