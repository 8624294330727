import React from 'react';
import { Col, Row, Modal, Card, Button, CloseButton } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import SpinningButton from './spinningButton';

function ConfirmationDialog(props) {
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [error, setError] = React.useState('');

    const onConfirm = async () => {
        setIsSubmitting(true);
        try {
            await props.onConfirm();
        }
        catch(err) {
            setError(err.message);
        }
        
        setIsSubmitting(false);
    }

    return (
        <Modal size="md" show={props.show} onHide={props.onCancel}>
            <Card>
                <Card.Header>
                    <Row className="align-items-center">
                        <Col>
                            <div className="text-end">
                                <CloseButton onClick={props.onCancel}/>
                            </div>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row className="p-4">
                        <Col>
                            <p className="lead text-center">
                                {props.message}
                            </p>
                        </Col>
                    </Row>
                    {error &&
                        <Row>
                            <Col className="text-center mb-4">
                                <p className="text-danger">
                                    {error}
                                </p>
                            </Col>
                        </Row>
                    }
                </Card.Body>
                <Card.Footer>
                    <Row>
                        <Col>
                            <div className="d-grid gap-2">
                                <Button className="fontsize" variant="outline-secondary" isSubmitting={isSubmitting}
                                                onClick={props.onCancel}>
                                    <Trans i18nKey="pageList.cancel">Cancel</Trans>
                                </Button>
                            </div>
                        </Col>
                        <Col>
                            <div className="d-grid gap-2">
                                <SpinningButton className="fontsize" variant="danger" isSubmitting={isSubmitting}
                                                onClick={onConfirm}>
                                    <Trans i18nKey="pageList.delete">Delete</Trans>
                                </SpinningButton>
                            </div>
                        </Col>
                    </Row>
                </Card.Footer>
                </Card>
        </Modal>
    )

}



export default ConfirmationDialog;