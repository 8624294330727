import React from 'react'
import { Formik } from 'formik';
import { Container, Card, Col, Row, Form, Alert, Image, Button } from 'react-bootstrap';
import { Navigate, useNavigate, useLocation, Link } from "react-router-dom";
import { SpinningButton } from '../components';
import * as yup from 'yup';
import { UserApi } from '../api/entities';
import { lineSplit } from '../helpers';
import './login.css';


const schema = yup.object().shape({
    password: yup.string().required('Password is required'),
    password_repeat: yup.string().required('Password confirmation is required').oneOf([yup.ref('password')], 'Passwords do not match')
});

function ResetPassword() {
    const [submitted, setSubmitted] = React.useState(false);

    let location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    var email = queryParams.get('e');
    var token = queryParams.get('t');


    function ResetPasswordForm() {
        return (
            <Formik
                initialValues={{ password: '', password_repeat: '' }}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting, setStatus, setErrors }) => {
                    try {
                        await UserApi.reset_password_confirm(email, token, values.password);
                    }
                    catch(err) {
                        if(err.name === 'ApiError' && err.errors && Object.keys(err.errors).length)
                        {
                            setErrors({
                                'password' : err.errors.Password,
                            });
                        }
                        else
                        {
                            setErrors({
                                'general' : err.message,
                            });
                        }
                    }
                }}
            >
            {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, isSubmitting, errors, status }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="ResetPasswordFormPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" name="password" onChange={handleChange} value={values.password} isInvalid={touched.password && !!errors.password}/>
                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="ResetPasswordFormPasswordRepeat">
                        <Form.Label>Repeat password</Form.Label>
                        <Form.Control type="password" name="password_repeat" onChange={handleChange} value={values.password_repeat} isInvalid={touched.password_repeat && !!errors.password_repeat}/>
                        <Form.Control.Feedback type="invalid">{errors.password_repeat}</Form.Control.Feedback>
                    </Form.Group>
    
                    {status && <Alert variant="danger">{status}</Alert>}
                    <div className="d-grid gap-2">
                        <SpinningButton variant="primary" type="submit" isSubmitting={isSubmitting}>
                            Reset password
                        </SpinningButton>
                    </div>
                    { errors.general &&
                        <Row>
                            <Col className="text-center mb-4">
                                <p className="text-danger">
                                    {lineSplit(errors.general)}
                                </p>
                            </Col>
                        </Row>
                    }
                </Form>
            )}
            </Formik>
        )
    }



    var reset_form = (
        <Container fluid className="h-100">
            <Row className="justify-content-center align-items-center h-100 pb-5">
                <Col sm={8} md={6} lg={4} xl={3}>
                    <Card>
                        <Card.Img id="logo" className="py-3 px-5" src={require('../assets/logo.png')} />
                        <Card.Body>
                            <div className="d-grid gap-2">
                                <p className='text-center px-2'>
                                    Please enter your new password below
                                </p>
                            </div>
                            <ResetPasswordForm />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );

    var reset_done = (
        <Container fluid className="h-100">
            <Row className="justify-content-center align-items-center h-100 pb-5">
                <Col sm={8} md={6} lg={4} xl={3}>
                    <Card>
                        <Card.Img id="logo" className="py-3 px-5" src={require('../assets/logo.png')} />
                        <Card.Body>
                            <div className="d-grid gap-2">
                                <p className='text-center px-2'>
                                    Your password has been reset. You may go proceed to login now!
                                    <Button className="fontsize" variant="link" as={Link} to="/login">
                                        Login here
                                    </Button>
                                </p>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );

    return submitted ? reset_done : reset_form;
}

export default ResetPassword;
