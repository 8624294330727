
function JSONValidator(value, context)
{
    try {
        JSON.parse(value);
        return true;
    }
    catch {
        return false;
    }
}

export default JSONValidator;