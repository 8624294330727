import React from 'react'
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { EventTypeApi } from '../api/entities';
import { AppFormInput } from '../components/';
import BasePageDetail from './base_detail';

import { EnsureNull } from '../helpers';

function EventTypeDetail() {
    const { t } = useTranslation();
    const context = useOutletContext();

    return <BasePageDetail
                title={t('pageList.eventtype', {count: 2})}
                subtitle={t('pageList.eventtype', {count: 1})}
                api={EventTypeApi}
                canChange={context.me.has_permission('EventType.Change')}
                canDelete={context.me.has_permission('EventType.Delete')}
                appForm={(entity, activeOptions) => 
                    <>
                        <AppFormInput column title={t('pageList.name')} name='name' type='text'
                                      validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={`${t('pageList.duration')} (${t('pageList.minutes')})`} name='duration' type='text'
                                      validator={yup.number().transform(EnsureNull).positive().nullable().required()} required={true}/>

                        <AppFormInput column title={`${t('pageList.notifyBefore')} (${t('pageList.minutes')})`} name='notify_before' type='text'
                                      validator={yup.number().transform(EnsureNull).positive('Field should be a positive number or empty').nullable()}/>

                        <AppFormInput column title={t('pageList.organisation', {count : 1})} name='organisation' type='select'
                                      options={activeOptions['organisations'].map(x => ({ "label": x.name, "value": x.id }))}
                                      validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={t('pageList.availableChannels')} name='available_channels' type='multiselect'
                                      options={activeOptions['available_channels'].map(x => ({ "label": x.name, "value": x.id }))}
                                      validator={yup.array().of(yup.string())}/>
                    </>
                }
            />;
}

export default EventTypeDetail;