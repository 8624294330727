import React from 'react';
import { Button, Card, Col, Form, Row, CloseButton } from 'react-bootstrap';
import ConfirmationDialog from './confirmation_dialog';
import { Trash } from 'iconoir-react';
import { Trans } from 'react-i18next';

function AppFormCard(props) {
    const [showModal, setShowModal] = React.useState(false);

    const onDeleteConfirm = async () => {
        await props.onDelete();
        setShowModal(false);
    };

    return (
        <>
            <ConfirmationDialog show={showModal}
                                message={<><Trans i18nKey="pageList.confirmPrompt">Are you sure you want to delete</Trans><br/><strong>{props.name}</strong></>}
                                onConfirm={onDeleteConfirm}
                                onCancel={() => setShowModal(false)}/>
            <Card>
                <Card.Header>
                            <div className="d-flex justify-content-between align-items-center">
                                <strong>{props.title}</strong>
                                <div className="d-flex align-items-center">
                                <span className='mx-3'>
                                    {props.customAction}
                                </span>
                                {props.onDelete &&
                                    <Button
                                        variant="outline-danger"
                                        size="sm"
                                        disabled={!props.canDelete}
                                        onClick={() => setShowModal(true)}
                                    >
                                        {
                                            props.deleteLabel ? (
                                                <>{props.deleteLabel}</>
                                            ) : (
                                                <><Trash/></>
                                            )
                                        }
                                        
                                    </Button>
                                }
                                { props.onCancel &&
                                    <CloseButton className="ms-4" onClick={props.onCancel}/>
                                }
                                </div>
                            </div>
                </Card.Header>
                <Card.Body>
                    {props.children}
                </Card.Body>
            </Card>
        </>
    );
}

export default AppFormCard;