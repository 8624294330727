import { BaseModel }  from './base_model';
import { OrganisationApi, TemplateUnavailabilityApi } from '../api/entities';
import apiFactory from '../api/api_factory';
import moment from 'moment';
import { TemplateUnavailability } from './unavailability';

class ScheduleTemplate extends BaseModel {
    #organisation_api = apiFactory.get_instance(OrganisationApi);
    #unavailability_api = apiFactory.get_instance(TemplateUnavailabilityApi);

    _get_related_api() {
        return {
            "organisations" : this.#organisation_api
        }
    }

    async get_active_options() {
        return {
            'organisations': await this.get_related_options('organisations')
        }
    }

    async get_recurring_unavailabilities(start_date, end_date) {
        var unv = [];
        var rec_unv = await this.#unavailability_api.get_objects({
            "TemplateId" : this.id,
            "Recurring" : true
        });

        // Recurring unavailabilities have correct day of the week and time, but
        // the date is random. We need to change the date part to be representative
        // of the current range
        var date_list = [];
        for(var d = moment(start_date); d < moment(end_date); d.add(1, 'day'))
            date_list.push(d.clone());

        rec_unv.forEach(unv_elem => {
            var unv_start = moment(unv_elem.start);
            var unv_end = moment(unv_elem.end);
            var unv_duration = unv_end.diff(unv_start);

            date_list.forEach(date_elem => {
                if(date_elem.day() === unv_start.day()) {
                    var new_start = moment({
                        year: date_elem.year(),
                        month: date_elem.month(),
                        day: date_elem.date(),
                        hours: unv_start.hours(),
                        minutes: unv_start.minutes(),
                        seconds: unv_start.seconds()
                    });
                    
                    var new_end = new_start.clone().add(unv_duration);

                    var new_unv = new TemplateUnavailability();
                    new_unv.id = unv_elem.id;
                    new_unv.user_id = unv_elem.user_id;
                    new_unv.description = unv_elem.description;
                    new_unv.start = new_start.toDate();
                    new_unv.end = new_end.toDate();
                    new_unv.recurring = unv_elem.recurring;

                    unv.push(new_unv);
                }
            });
        });

        return unv;
    }

    async get_unavailabilities(start_date, end_date) {
        var unv = await this.#unavailability_api.get_objects({
            "TemplateId" : this.id,
            "DateStart" : start_date.toISOString(),
            "DateEnd" : end_date.toISOString()
        });

        return unv;
    }

    async get_all_unavailabilities(start_date, end_date) {
        var res = await Promise.all([
            this.get_unavailabilities(start_date, end_date),
            this.get_recurring_unavailabilities(start_date, end_date)
        ]);

        return res.flatMap(subArray => subArray);
    }


    async import_from_api(data) {
        this.id = data.id;
        this.name = data.name;
        this.organisation = await this.get_related_object('organisations', data.organisationId);
    };


    export_to_api() {
        const data_object = {
            "name" : this.name,
            "organisationId" : this.organisation?.id
        }

        if(this.id)
            data_object.id = this.id;

        return data_object;
    }


    async import_from_form(data) {
        this.name = data.name;

        if(this.organisation_id !== data.organisation) {
            this.organisation_id = data.organisation;
            this.organisation = await this.get_related_object('organisations', this.organisation_id);
        }
    }


    export_to_form() {
        return {
            'id' : this.id,
            'name' : this.name,
            'organisation' : this.organisation?.id,
        }
    }

    static errors_to_form(errors) {
        return {
            'id' : errors.Id,
            'name' : errors.Name,
            'organisation' : errors.OrganisationId,
        }
    }

    toString() {
        return this.name;
    }
}


export default ScheduleTemplate;
