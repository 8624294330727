import moment from 'moment';

import { BaseModel }  from './base_model';
import { EventChannelApi, OrganisationApi } from '../api/entities';
import apiFactory from '../api/api_factory';

class EventType extends BaseModel {
    #organisation_api = apiFactory.get_instance(OrganisationApi);
    #eventchannel_api = apiFactory.get_instance(EventChannelApi);

    _get_related_api() {
        return {
            "organisations" : this.#organisation_api,
            "eventchannels" : this.#eventchannel_api
        }
    }

    async get_active_options() {
        var organisations = await this.get_related_options('organisations');

        var channels = [];
        if(this.organisation_id != null)
        {
            var channel_ids = organisations.find(s => s.id == this.organisation_id).eventchannels;
            channels = (await this.get_related_options('eventchannels')).filter(channel => channel_ids.includes(channel.id));
        }

        return {
            'organisations': organisations,
            'available_channels' : channels
        }
    }

    async import_from_api(data) {
        this.id = data.id;
        this.name = data.name;
        this.duration = moment.duration(data.duration);
        this.notifyBefore = data.notifyBefore ? moment.duration(data.notifyBefore) : null;
        this.organisation_id = data.organisationId;
        this.organisation = await this.get_related_object('organisations', this.organisation_id);

        this.available_channels = data.availableChannels;
    };


    export_to_api() {
        const data_object = {
            "name" : this.name,
            "duration" : this.duration.toISOString(),
            "notifyBefore" : this.notifyBefore?.toISOString(),
            "organisationId" : this.organisation_id,
            "availableChannels" : this.available_channels
        }

        if(this.id)
            data_object.id = this.id;

        return data_object;
    }


    async import_from_form(data) {
        this.name = data.name;
        this.duration = moment.duration(data.duration, 'minutes');
        this.notifyBefore = data.notify_before ? moment.duration(data.notify_before, 'minutes') : null;
        if(this.organisation_id !== data.organisation) {
            this.organisation_id = data.organisation;
            this.organisation = await this.get_related_object('organisations', this.organisation_id);
        }

        if(data.available_channels)
            this.available_channels = data.available_channels;
        else
            this.available_channels = [];
    }


    export_to_form() {
        return {
            'id' : this.id,
            'name' : this.name,
            'duration' : this.duration ? this.duration.asMinutes() : null,
            'notify_before' : this.notifyBefore ? this.notifyBefore.asMinutes() : null,
            'organisation' : this.organisation_id,
            'available_channels' : this.available_channels
        }
    }

    static errors_to_form(errors) {
        return {
            'id' : errors.Id,
            'name' : errors.Name,
            'duration' : errors.Duration,
            'notify_before' : errors.NotifyBefore,
            'organisation' : errors.OrganisationId,
            'available_channels' : errors.AvailableChannels
        }
    }


    toString() {
        return this.name;
    }
}

export default EventType;