import React from 'react';
import { authProviderFactory } from '../api/auth';
import apiFactory from '../api/api_factory';
import { UserApi } from '../api/entities';
import { Navigate, useLocation } from "react-router-dom";

function OidcAuth() {
    let location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    var state = queryParams.get('state');
    var code = queryParams.get('code');

    const [authenticated, setAuthenticated] = React.useState(null);


    React.useEffect(() => {
        const provider = authProviderFactory.getActiveProvider();

        if(state && code && provider.isWaitingAuthentication() && authenticated === null) {
            provider.authenticate(state, code)
                    .then(x => {
                        setAuthenticated(true);
                    })
                    .catch(error => {
                        console.error('Failed to authenticate with external provider: ', error);
                        provider.logout();
                        setAuthenticated(false);
                    });
        }
    }, []);

    return (
        <>
            {authenticated === true &&
                <Navigate to="/" replace/>
            }
            {(authenticated === false) &&
                <Navigate to="/login" replace/>
            }
        </>
    );
}

export default OidcAuth;