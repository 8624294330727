import { useTranslation, Trans } from 'react-i18next';
import Select from 'react-select';
import moment from 'moment';
import { Form } from 'react-bootstrap';

function AppSearchBar(props) {
    const { t } = useTranslation();


    return (
        <>
        {
            props.filters && props.filters.map((filter, idx) => (
                <div className='flex-fill filter-item p-1 g-col-1' key={'filterCol' + idx}>
                {filter.type == 'select' &&
                    <Select
                        inputId={"filterSelect-" + idx}
                        options={[{ "label": "All", "value": "" }, ...filter.options]}
                        placeholder={filter.placeholder ? filter.placeholder : "Select " + filter.name}
                        value={(filter.value !== null && filter.value !== undefined) ? filter.options.find(o => o.value == filter.value) : null}
                        onChange={(option) => {
                            if(option.value === "")
                                filter.setValue(filter.key, null);
                            else
                                filter.setValue(filter.key, option.value);
                        }}
                        styles={{
                            valueContainer: (provided, state) => ({
                                ...provided,
                                padding: '2px 6px'
                            }),
                            control: (provided, state) => ({
                                ...provided,
                                minHeight: '0'  
                            }),
                            dropdownIndicator : (provided, state) => ({
                                ...provided,
                                padding: '0px 8px'
                            })
                        }}
                    ></Select>
                }
                {filter.type == 'datetime-local' && 
                    <Form.Control size="md" className="fontsize" type="datetime-local"
                                value={moment(filter.value).format('YYYY-MM-DDTHH:mm:ss')}
                                onChange={(d) => filter.setValue(filter.key, new Date(d.target.value).toISOString())}/>
                }
                </div>
            ))
        }
        {props.handleSearch &&
            <div className='flex-fill filter-item p-1 g-col-1'>
                <Form.Control className="fontsize" type="text" placeholder={t('pageList.search')}
                            onChange={(event) => {
                                const searchData = event.target.value;
                                if(searchData === "")
                                    props.handleSearch(null);
                                else
                                    props.handleSearch(searchData);
                            }}/>
            </div>
        }
        </>
    );
}


export default AppSearchBar;