import { AddCircle, Car, DoubleCheck, RemoveUser, ThumbsUp, TriangleFlagTwoStripes } from 'iconoir-react';
import moment from 'moment';

import { BaseModel }  from './base_model';
import { BranchApi, ServiceApi, UserApi, GuestApi, EventChannelApi } from '../api/entities';
import apiFactory from '../api/api_factory';

class Event extends BaseModel {
    constructor() {
        super();
        this.guests = [];
    }


    static statuses = [
        {
            name : "New",
            icon: <AddCircle />,
            value: "0"
        },
        {
            name: "Confirmed",
            icon: <ThumbsUp />,
            value: "1"
        },
        {
            name: "Arrived",
            icon: <Car />,
            value: "2"
        },
        {
            name: "No-show",
            icon: <RemoveUser />,
            value: "3"
        },
        {
            name: "Started",
            icon: <TriangleFlagTwoStripes />,
            value: "4"
        },
        {
            name: "Completed",
            icon: <DoubleCheck />,
            value: "5"
        }
    ];

    static get_statuses() {
        return Event.statuses;
    }


    #branch_api = apiFactory.get_instance(BranchApi);
    #service_api = apiFactory.get_instance(ServiceApi);
    #user_api = apiFactory.get_instance(UserApi);
    #guest_api = apiFactory.get_instance(GuestApi);
    #eventchannel_api = apiFactory.get_instance(EventChannelApi);

    _get_related_api() {
        return {
            "branches" : this.#branch_api,
            "services" : this.#service_api,
            "users" : this.#user_api,
            "guests" : this.#guest_api,
            "channels" : this.#eventchannel_api
        }
    }

    get_status() {
        return Event.statuses.find(s => s.value == this.status);
    }

    get_service() {
        return this.services?.length > 0 ? this.services[0] : null;
    }

    get_user() {
        return this.users?.length > 0 ? this.users[0] : null;
    }

    async get_active_options() {
        var service_users = (await this.get_related_options('users'))
            .filter(user => this.get_service()?.users?.some(serviceUser => serviceUser.id === user.id))
        var active_users = service_users.filter(user => this.branch ? user.branch?.id == this.branch.id : true);

        var active_branchIds = service_users.map(user => user.branch?.id);
        var active_branches = (await this.get_related_options('branches'))
                                         .filter(branch => active_branchIds.includes(branch.id));

        return {
            'branches': active_branches,
            'services': await this.get_related_options('services'),
            'users': active_users,
            'guests': await this.get_related_options('guests'),
            'channels' : this.get_service() ? (await this.get_service().eventtype.get_active_options())['available_channels'] : []
        }
    }

    
    async import_from_api(data) {
        this.id = data.id;
        this.title = data.title;
        this.description = data.description;
        this.type_id = data.typeId;
        this.start = new Date(data.start);
        this.end = new Date(data.end);
        this.cancelled = data.cancelled;
        this.status = data.status?.toString();
        this.createdon = new Date(data.createdOn);
        this.updatedon = new Date(data.updatedOn);

        this.branch = await this.get_related_object('branches', data.branchId);
        this.channel = await this.get_related_object('channels', data.channelId);

        this.services = await Promise.all(data.services.map(async obj_id => await this.get_related_object('services', obj_id)));
        this.users = await Promise.all(data.users.map(async obj_id => await this.get_related_object('users', obj_id)));
        this.guests = await Promise.all(data.guests.map(async obj_id => await this.get_related_object('guests', obj_id)));
        this.channelProviderUrl = data.channelProviderUrl;
        this.eventNumber = data.eventNumber;
    };

    export_to_api() {
        var data_object = {
            "title" : this.title,
            "description" : this.description,
            "typeId" : this.type_id ? this.type_id : this.get_service().eventtype.id,
            "start" : this.start,
            "end" : this.end,
            "cancelled" : this.cancelled,
            "status" : Number(this.status),
            "branchId" : this.branch ? this.branch.id : null,
            "services" : this.services.map(obj => obj.id),
            "users" : this.users.map(obj => obj.id),
            "guests" : this.guests.map(obj => obj.id),
            "channelId" : this.channel ? this.channel.id : null
        };
        if(this.id)
            data_object.id = this.id;

        return data_object;
    }

    async import_from_form(data) {
        this.title = data.title;
        this.description = data.description;
        this.start = new Date(data.start);
        this.cancelled = data.cancelled;
        this.status = data.status;

        if(this.branch?.id !== data.branch) {
            this.branch = await this.get_related_object('branches', data.branch);
        }

        if(this.channel?.id !== data.channel) {
            this.channel = await this.get_related_object('channels', data.channel);
        }

        if(data.service != null) {
            this.services = await Promise.all([data.service].map(async obj_id => await this.get_related_object('services', obj_id)));
        }
        else {
            this.services = [];
        }
        
        if(data.user != null) {
            this.users = await Promise.all([data.user].map(async obj_id => await this.get_related_object('users', obj_id)));
        }
        else {
            this.users = [];
        }

        if(data.guests != null) {
            this.guests = await Promise.all(data.guests.map(async obj_id => await this.get_related_object('guests', obj_id)));
        }
        else {
            this.guests = [];
        }

        this.end = this.get_service() ? moment(this.start).add(this.get_service().eventtype.duration).toDate() : null;
    }

    export_to_form() {
        return {
            'id' : this.id,
            'title' : this.title,
            'description' : this.description,
            'start' : this.start,
            'end' : this.end,
            'cancelled' : this.cancelled,
            'status' : this.status,
            'createdon' : this.createdon,
            'updatedon' : this.updatedon,
            'channel' : this.channel?.id,
            'branch' : this.branch?.id,
            'service' : this.get_service()?.id,
            'user' : this.get_user()?.id,
            'guests' : this.guests ? this.guests.map(obj => obj.id) : [],
            'eventNumber': this.eventNumber
        }
    }

    static errors_to_form(errors) {
        return {
            'id' : errors.Id,
            'title' : errors.Title,
            'description' : errors.Description,
            'start' : errors.Start,
            'end' : errors.End,
            'cancelled' : errors.Cancelled,
            'status' : errors.Status,
            'createdon' : errors.CreatedOn,
            'updatedon' : errors.UpdatedOn,
            'channel' : errors.Channel,
            'branch' : errors.BranchId,
            'service' : errors.Services,
            'user' : errors.Users,
            'guest' : errors.Guests
        }
    }

    toString() {
        return this.title;
    }
}

export default Event;