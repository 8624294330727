import React from 'react'
import { useParams, useNavigate, useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { toast } from 'react-toastify';


import apiFactory from '../api/api_factory';
import { AppForm, AppFormCard, AppSpinnerOverlay } from '../components/';
import { FormSubmitError } from '../components/exceptions';

function BasePageDetail(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const context = useOutletContext();
    const me = context.me;

    const entityApi = apiFactory.get_instance(props.api);

    const [entity, setEntity] = React.useState(null);
    const [relatedOptions, setRelatedOptions] = React.useState(null);
    const [activeOptions, setActiveOptions] = React.useState(null);
    const [error, setError] = React.useState(null);

    const [activeKey, setActiveKey] = React.useState('detail');
    const [initialValues, setInitialValues] = React.useState(null);

    let { id } = useParams();

    React.useEffect(() => {
        async function fetchData() {
            let current_entity;
            if(id === 'new') {
                current_entity = new entityApi.MODEL_CLASS();
            }
            else {
                current_entity = await entityApi.get_object(id);
            }
            var values = current_entity.export_to_form();
            setInitialValues(values);
            setEntity(current_entity);

            var all_options = await current_entity.get_all_related_options();
            setRelatedOptions(all_options);

            var active_options = await current_entity.get_active_options();
            setActiveOptions(active_options);
        }

        fetchData().catch(err => setError(err));
    }, []);


    const handleSubmit = async (values) => {
        await entity.import_from_form(values);

        try {
            if(id === 'new')
                await entityApi.add_object(entity);
            else
                await entityApi.update_object(id, entity);
        }
        catch(err) {
            var errors = [];
            if(err.name === 'ApiError' && err.errors && Object.keys(err.errors).length)
                errors = entityApi.MODEL_CLASS.errors_to_form(err.errors);
            else
                errors['general'] = err.message;
            throw new FormSubmitError(errors);
        }
        return entity.toString();
    }

    const handleDelete = async () => {
        await entityApi.delete_object(entity.id);
        navigate(-1);
    }

    const handleChange = async (current_values, next_values) => {
        entity.import_from_form(next_values);
        var active_options = await entity.get_active_options();
        setActiveOptions(active_options);

        var processed_values = entity.export_to_form();
        for (const key in next_values) {
            if(processed_values.hasOwnProperty(key))
                next_values[key] = processed_values[key];
        }
    };

    if(!entity || !relatedOptions || !activeOptions || !me) {
        return (
            <>
                {error && <Alert key='danger' variant='danger'>Error: {error.message}</Alert>}
                {!error && <AppSpinnerOverlay/>}
            </>
        )
    }

    const detail_form = (
        <>
            <AppFormCard id={entity.id} title={props.subtitle} name={entity.toString()} canDelete={id !== 'new' && props.canDelete} onDelete={handleDelete}>
                <AppForm name={props.subtitle}
                         canChange={props.canChange}
                         values={initialValues}
                         onSubmit={handleSubmit}
                         onChange={handleChange}
                         OnSuccess={() => { toast.success(`${entity.toString()} saved successfully!`); navigate(-1);}}
                         onCancel={() => navigate(-1)}
                         preserve_values={false}>

                    {props.appForm(entity, activeOptions)}
                    
                </AppForm>
            </AppFormCard>
        </>
    )

    var single_tab = (
        <Tabs
            defaultActiveKey="detail"
            id="detail_tabs"
            className="mb-3">
            <Tab eventKey="detail" title={t('pageList.details')}>
                { detail_form }
            </Tab>
        </Tabs>
    );

    var all_tabs = props.tabs ? (
        <Tabs
            defaultActiveKey="detail"
            id="detail_tabs"
            className="mb-3"
            activeKey={activeKey}
            onSelect={(key) => setActiveKey(key)}>

            <Tab eventKey="detail" title={t('pageList.details')}>
                { activeKey === 'detail' && detail_form }
            </Tab>

            {props.tabs.map((tab, idx) => (
                <Tab eventKey={tab} title={tab}>
                    { activeKey === tab && React.cloneElement(props.children[idx], {entity: entity}) }
                </Tab>
            ))}
        </Tabs>
    ) : null;

    return (
        <>
            <h4><small className="text-muted">{props.title}</small> . <strong>{entity.toString()}</strong></h4>
            { id === 'new' || props.tabs == null ? single_tab : all_tabs }
        </>
    );
}

export default BasePageDetail;
