import React from 'react';
import { i18n } from './i18n';
import packageJson from '../package.json';
import hash from "./hash.json"

const lineSplit = (text) => {
    const lines = text.split('\n');

    return (
        <>
        {lines.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
        ))}
        </>
    )
}

// This function is used as a transformer in yup. If a number field
// is an empty string, it should be converted to null, not NaN
const EnsureNull = (value, originalValue) => {
    if(isNaN(value))
    {
        if(typeof originalValue === 'string' && originalValue.length === 0)
            return null;
    }

    return value;
};

function toLocaleString(dateTime) {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      };
    return new Intl.DateTimeFormat(i18n.language, options).format(dateTime);
}


function getVersionString() {
    return `${packageJson.version}+${hash.GIT_HASH}`;
}

export { lineSplit, EnsureNull, toLocaleString, getVersionString }