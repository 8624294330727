import { 
    OrganisationApi, BranchApi,
    UserApi, RoleApi,
    PermissionApi, EventApi,
    EventTypeApi, ServiceApi,
    GuestApi, CommProviderApi, CalendarProviderApi, ScheduleTemplateApi, 
    UserUnavailabilityApi, BranchUnavailabilityApi, TemplateUnavailabilityApi,
    EventChannelApi, StatisticsApi
} from './entities'

class ApiFactory {
    #organisationApi = null;
    #branchApi = null;
    #userApi = null;
    #roleApi = null;
    #permissionApi = null;
    #eventApi = null;
    #eventtypeApi = null;
    #serviceApi = null;
    #guestApi = null;
    #commProviderApi = null;
    #calendarProviderApi = null;
    #eventChannelApi = null;
    #branchUnavailabilityApi = null;
    #userUnavailabilityApi = null;
    #templateUnavailabilityApi = null;
    #scheduleTemplateApi = null;
    #statisticsApi = null;

    clear_all_cache() {
        if(this.#organisationApi !== null)
            this.#organisationApi.clear_cache();
        if(this.#branchApi !== null)
            this.#branchApi.clear_cache();
        if(this.#userApi !== null)
            this.#userApi.clear_cache();
        if(this.#roleApi !== null)
            this.#roleApi.clear_cache();
        if(this.#permissionApi !== null)
            this.#permissionApi.clear_cache();
        if(this.#eventApi !== null)
            this.#eventApi.clear_cache();
        if(this.#eventtypeApi !== null)
            this.#eventtypeApi.clear_cache();
        if(this.#serviceApi !== null)
            this.#serviceApi.clear_cache();
        if(this.#guestApi !== null)
            this.#guestApi.clear_cache();
        if(this.#commProviderApi !== null)
            this.#commProviderApi.clear_cache();
        if(this.#calendarProviderApi !== null)
            this.#calendarProviderApi.clear_cache();
        if(this.#eventChannelApi !== null)
            this.#eventChannelApi.clear_cache();
        if(this.#branchUnavailabilityApi !== null)
            this.#branchUnavailabilityApi.clear_cache();
        if(this.#userUnavailabilityApi !== null)
            this.#userUnavailabilityApi.clear_cache();
        if(this.#templateUnavailabilityApi !== null)
            this.#templateUnavailabilityApi.clear_cache();
        if(this.#scheduleTemplateApi !== null)
            this.#scheduleTemplateApi.clear_cache();
    }


    _clear_cache(internal_instance) {
        if(internal_instance != null) {
            internal_instance.clear_cache()
        }
    }


    get_instance(api_class) {
        switch(api_class)
        {
            case OrganisationApi:
                if(this.#organisationApi === null)
                    this.#organisationApi = new OrganisationApi();
                return this.#organisationApi;
            case BranchApi:
                if(this.#branchApi === null)
                    this.#branchApi = new BranchApi();
                return this.#branchApi;
            case UserApi:
                if(this.#userApi === null)
                    this.#userApi = new UserApi();
                return this.#userApi;
            case RoleApi:
                if(this.#roleApi === null)
                    this.#roleApi = new RoleApi();
                return this.#roleApi;
            case PermissionApi:
                if(this.#permissionApi === null)
                    this.#permissionApi = new PermissionApi();
                return this.#permissionApi;
            case EventApi:
                if(this.#eventApi === null)
                    this.#eventApi = new EventApi();
                return this.#eventApi;
            case EventTypeApi:
                if(this.#eventtypeApi === null)
                    this.#eventtypeApi = new EventTypeApi();
                return this.#eventtypeApi;
            case ServiceApi:
                if(this.#serviceApi === null)
                    this.#serviceApi = new ServiceApi();
                return this.#serviceApi;
            case GuestApi:
                if(this.#guestApi === null)
                    this.#guestApi = new GuestApi();
                return this.#guestApi;
            case CommProviderApi:
                if(this.#commProviderApi === null)
                    this.#commProviderApi = new CommProviderApi();
                return this.#commProviderApi;
            case CalendarProviderApi:
                if(this.#calendarProviderApi === null)
                    this.#calendarProviderApi = new CalendarProviderApi();
                return this.#calendarProviderApi;
            case EventChannelApi:
                if(this.#eventChannelApi === null)
                    this.#eventChannelApi = new EventChannelApi();
                return this.#eventChannelApi
            case BranchUnavailabilityApi:
                if(this.#branchUnavailabilityApi === null)
                    this.#branchUnavailabilityApi = new BranchUnavailabilityApi();
                return this.#branchUnavailabilityApi;
            case UserUnavailabilityApi:
                if(this.#userUnavailabilityApi === null)
                    this.#userUnavailabilityApi = new UserUnavailabilityApi();
                return this.#userUnavailabilityApi;
            case TemplateUnavailabilityApi:
                if(this.#templateUnavailabilityApi === null)
                    this.#templateUnavailabilityApi = new TemplateUnavailabilityApi();
                return this.#templateUnavailabilityApi;
            case ScheduleTemplateApi:
                if(this.#scheduleTemplateApi === null)
                    this.#scheduleTemplateApi = new ScheduleTemplateApi();
                return this.#scheduleTemplateApi;
            case StatisticsApi:
                if(this.#statisticsApi === null)
                    this.#statisticsApi = new StatisticsApi();
                return this.#statisticsApi;
            default:
                throw new TypeError('Invalid API class');
        }
    }
}

var apiFactory = new ApiFactory();


export default apiFactory;
