import React from 'react'
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { BranchApi } from '../api/entities';
import BranchSchedule from './branch_schedule';
import BranchUnavailabilitySchedule from './branch_unavailability';
import { AppFormInput } from '../components/';
import BasePageDetail from './base_detail';

import { EnsureNull } from '../helpers';

function BranchDetail() {
    const { t } = useTranslation();
    const context = useOutletContext();

    return <BasePageDetail
                title={t('pageList.branch', {count: 2})}
                subtitle={t('pageList.branch', {count: 1})}
                api={BranchApi}
                canChange={context.me.has_permission('Branch.Change')}
                canDelete={context.me.has_permission('Branch.Delete')}
                appForm={(entity, activeOptions) => 
                    <>
                        <AppFormInput column title={t('pageList.name')} name='name' type='text'
                                      validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={t('pageList.organisation', {count : 1})} name='organisation' type='select'
                                      options={activeOptions['organisations'].map(x => ({ "label": x.name, "value": x.id }))}
                                      validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={t('pageList.address')} name='address' type='text'
                                      validator={yup.string().nullable()}/>

                        <AppFormInput column title={t('pageList.phoneNumber')} name='phonenumber' type='text'
                                      validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={t('pageList.email')} name='email' type='text'
                                      validator={yup.string().email().nullable()}/>

                        <AppFormInput column title={t('pageList.longitude')} name='longitude' type='text'
                                      validator={yup.number().typeError('Field should be a number or empty').transform(EnsureNull).nullable().min(-180).max(180)}/>

                        <AppFormInput column title={t('pageList.latitude')} name='latitude' type='text'
                                      validator={yup.number().typeError('Field should be a number or empty').transform(EnsureNull).nullable().min(-90).max(90)}/>

                        <AppFormInput column title={t('pageList.active')} name='active' type='check'
                                      default={true} validator={yup.bool()} required={true}/>

                        <AppFormInput column title={t('pageList.scheduletemplate', {count: 2})} name='schedules' type='multiselect'
                                      options={activeOptions['schedules'].map(x => ({ "label": x.toString(), "value": x.id }))}
                                      validator={yup.array().of(yup.string())}/>
                    </>
                }
                tabs={[t('pageList.weeklyPlan'), t('pageList.planExceptions')]}
            >
                <BranchSchedule/>
                <BranchUnavailabilitySchedule/>
            </BasePageDetail>
}

export default BranchDetail;