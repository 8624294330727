import { Button, Spinner } from 'react-bootstrap';

function SpinningButton(props) {
    return (
        <Button {...props} disabled={props.disabled || props.isSubmitting}>
            {props.isSubmitting && <Spinner size='sm' animation="border" variant="light" />}
            {!props.isSubmitting && props.children}
        </Button>
      );
}


export default SpinningButton;
