import { Modal } from "react-bootstrap";
import { useTranslation, Trans } from 'react-i18next';
import * as yup from 'yup';

import AppFormCard from "./app_form_card";
import AppForm from "./app_form";
import AppFormInput from "./app_form_input";



function PasswordChangeForm(props) {
    const { t } = useTranslation();

    return (
        <Modal show={props.show} onHide={props.onCancel}>
            <AppFormCard title={t('pageList.changePassword')} onCancel={props.onCancel}>
                <AppForm
                    canChange={true}
                    values={{
                        "current_password" : "",
                        "new_password" : "",
                        "new_password_repeat" : ""
                    }}
                    onSubmit={props.onSubmit}
                    OnSuccess={props.onConfirm}
                    onCancel={props.onCancel}>
                    {props.requireCurrentPassword &&
                        <AppFormInput title={t('pageList.currentPassword')} name="current_password" type="password"
                                      validator={yup.string().nullable().required('Current password is a required field')}/>
                    }
                    <AppFormInput title={t('pageList.newPassword')} name="new_password" type="password"
                                  validator={yup.string().nullable().required('New password is a required field')}/>
                    <AppFormInput title={t('pageList.newPasswordRepeat')} name="new_password_repeat" type="password"
                                  validator={yup.string().nullable().required('New password repeat is a required field')
                                                .oneOf([yup.ref('new_password')], t('pageList.passwordMismatch'))}/>
                </AppForm>
            </AppFormCard>
        </Modal>
    );
}


export default PasswordChangeForm;