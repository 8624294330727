import React from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { CheckCircle, DeleteCircle, HelpCircle, Upload, Download, Plus } from 'iconoir-react';


import { AppSpinnerOverlay } from './spinner';
import AppSearchBar from './app_search_bar';

function AppTable(props) {
    const { t } = useTranslation();
    
    const [sortBy, setSortBy] = React.useState(null);
    const [sortAsc, setSortAsc] = React.useState(true);

    const fileInputRef = React.useRef(null);


    function sortValues(values) {
        if(sortBy !== null) {
            values = values.sort((a, b) => {
                a = a['data'][sortBy];
                b = b['data'][sortBy];

                if (a === b) {
                    return 0;
                }
                else if(a === null) {
                    return sortAsc ? 1 : -1;
                }
                else if(b === null) {
                    return sortAsc ? -1 : 1;
                }
                else if(a > b) {
                    return sortAsc ? 1 : -1;

                }
                else if(a < b) {
                    return sortAsc ? -1 : 1;
                }
            });
        }
        return values;
    }

    function changeSorting(by) {
        if(by !== sortBy) {
            setSortBy(by);
            setSortAsc(true);
        }
        else {
            setSortAsc(!sortAsc);
        }
    }


    function getPagination() {
        let items = [];
        for (let number = 1; number <= props.totalPages; number++) {
            items.push(
              <Pagination.Item key={number} active={number === props.page} onClick={() => props.handlePageChange(number)}>
                {number}
              </Pagination.Item>,
            );
        }

        return items;
    }

    const handleImportButtonClick = () => {
        fileInputRef.current.click();
      };

    return (
        <>
            <Card>
                <Card.Header>
                    <div className='d-flex flex-wrap'>
                        <div className='d-flex flex-row flex-wrap filter-container grid gap-0 column-gap-3'>
                            <AppSearchBar filters={props.filters} handleSearch={props.handleSearch}/>
                        </div>

                        <div className='d-flex flex-row flex-grow-1 justify-content-end align-items-center'>
                        {props.import &&
                            <span className='ms-2'>
                                <Button variant="outline-secondary" size="sm" onClick={handleImportButtonClick}><Upload/></Button>
                                <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={props.import}/>
                            </span>
                        }
                        {props.export && 
                            <span className='ms-2'>
                                <Button variant="outline-secondary" size="sm" onClick={props.export}><Download/></Button>
                            </span>
                        }
                            <span className='ms-2'>
                                <LinkContainer to={props.baseURL + '/new'}>
                                    <Button variant="outline-success" size="sm" disabled={!props.canChange}><Plus/></Button>
                                </LinkContainer>
                            </span>
                        </div>
                    </div>
                </Card.Header>
            {!props.isLoading && 
                    <Card.Body className="p-0">
                        <Table hover className='m-0'>
                        <thead>
                            <tr>
                                {props.headers.map((header, idx) => (
                                    <th key={'head-' + idx} onClick={() => changeSorting(idx)}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {sortValues(props.values).map((row) => {
                                return (
                                    <LinkContainer key={'link-' + row.id} to={props.baseURL + '/' + row.id}>
                                        <tr key={'tr-' + row.id}>
                                            {props.headers.map((header, idx) => {
                                                var item = row.data[idx];
                                                let value;
                                                switch(typeof item) {
                                                    case "boolean":
                                                        if(item == true) {
                                                            value = <CheckCircle color="#70bf2b"/>
                                                        }
                                                        else if(item == false) {
                                                            value = <DeleteCircle color="#dd4646"/>
                                                        }
                                                        else {
                                                            value = <HelpCircle color="grey"/>
                                                        }
                                                        break;
                                                    // case "object":
                                                    //     if(item !== null)
                                                    //         value = item.toString();
                                                    //     else
                                                    //         value = null;
                                                    //     break;
                                                    default:
                                                        value = item;
                                                        break;
                                                }
                                                return (<td key={'td-' + row.id + idx}>{value}</td>);
                                            })}
                                        </tr>
                                    </LinkContainer>
                                );
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            }
            {!props.isLoading && props.page &&
                <Card.Footer>
                    <Row>
                        <Col className="d-flex justify-content-start">
                        </Col>
                        <Col className="d-flex justify-content-center">
                            <Pagination size="sm" style={{margin : 0}}>
                                <Pagination.First onClick={() => props.handlePageChange(1)} />
                                <Pagination.Prev onClick={() => props.handlePageChange(props.page <= 1 ? 1 : props.page-1)} />
                                { getPagination() }
                                <Pagination.Next onClick={() => props.handlePageChange(props.page == props.totalPages ? props.totalPages : props.page+1)} />
                                <Pagination.Last onClick={() => props.handlePageChange(props.totalPages)} />
                            </Pagination>
                        </Col>
                        <Col className="d-flex justify-content-end">
                            {props.totalItems} <Trans i18nKey="pageList.totalItems">total items</Trans>
                        </Col>
                    </Row>
                </Card.Footer>
            }
            
            </Card>
            {props.isLoading && <AppSpinnerOverlay/>}
        </>
    )
}

export default AppTable;