import React from 'react'
import { Container, Card, Col, Row, Button } from 'react-bootstrap';
import { useNavigate, useLocation } from "react-router-dom";
import { authProviderFactory } from '../api/auth';

function Unauthorized() {
    let navigate = useNavigate();
    let location = useLocation();
    let from = location.state?.from?.pathname || "/";

    var provider = authProviderFactory.getActiveProvider();

    return (
        <Container fluid className="h-100">
            <Row className="justify-content-center align-items-center h-100 pb-5">
                <Col sm={8} md={6} lg={4} xl={4}>
                    <Card>
                        <Card.Img id="logo" className="py-3 px-5" src={require('../assets/logo.png')} />
                        <Card.Body>
                            <div className="d-grid gap-2">
                                <p className='text-center px-2'>
                                You are currently not authorized to access this application. Contact your
                                administrator to provide you access and then you may try again.
                                </p>
                            <Button className='mt-5 fontsize' onClick={() => { navigate(from); }}>
                                Try again
                            </Button>
                            <Button className="fontsize" onClick={() => { provider.logout(); navigate('/'); }}>
                                Logout
                            </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Unauthorized;
