import Spinner from 'react-bootstrap/Spinner';
import Overlay from 'react-bootstrap/Overlay';

function AppSpinner(props) {
    return (
        <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
      );
}

function AppSpinnerOverlay(props) {
    return (
        <>
            <Spinner animation="border" role="status" className="spinner">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div className="spinner-overlay" />
        </>
    );

}


export { AppSpinner, AppSpinnerOverlay };
