import { BaseModel }  from './base_model';
import { OrganisationApi, EventTypeApi, UserApi } from '../api/entities';
import apiFactory from '../api/api_factory';

class Service extends BaseModel {
    #organisation_api = apiFactory.get_instance(OrganisationApi);
    #eventtype_api = apiFactory.get_instance(EventTypeApi);
    #user_api = apiFactory.get_instance(UserApi);


    _get_related_api() {
        return {
            "organisations" : this.#organisation_api,
            "eventtypes" : this.#eventtype_api,
            "users" : this.#user_api
        }
    }

    async get_active_options() {
        return {
            'organisations': await this.get_related_options('organisations'),
            'eventtypes': (await this.get_related_options('eventtypes')).filter(x => x.organisation_id === this.organisation_id),
            'users': (await this.get_related_options('users')).filter(x => x.organisation_id === this.organisation_id)
        }
    }


    async import_from_api(data) {
        this.id = data.id;
        this.name = data.name;
        this.autoassign = data.autoAssign;
        this.organisation_id = data.organisationId;
        this.organisation = await this.get_related_object('organisations', this.organisation_id);
        this.eventtype_id = data.eventTypeId;
        this.eventtype = await this.get_related_object('eventtypes', this.eventtype_id);
        this.users = await Promise.all(data.users.map(async obj_id => await this.get_related_object('users', obj_id)));
        this.tags = data.tags ? data.tags : {};
    };


    export_to_api() {
        const data_object = {
            "name" : this.name,
            "autoassign" : this.autoassign,
            "organisationId" : this.organisation_id,
            "eventTypeId" : this.eventtype_id,
            "users" : this.users.map(obj => obj.id),
            "tags" : this.tags
        }

        if(this.id)
            data_object.id = this.id;

        return data_object;
    }


    async import_from_form(data) {
        this.name = data.name;
        this.autoassign = data.autoassign;
        if(this.organisation_id !== data.organisation) {
            this.organisation_id = data.organisation;
            this.organisation = await this.get_related_object('organisations', this.organisation_id);
        }
        if(this.eventtype_id !== data.eventtype) {
            this.eventtype_id = data.eventtype;
            this.eventtype = await this.get_related_object('eventtypes', this.eventtype_id);
        }

        if(data.users !== null)
            this.users = await Promise.all(data.users.map(async obj_id => await this.get_related_object('users', obj_id)));
        else
            this.users = [];
    }


    export_to_form() {
        return {
            'id' : this.id,
            'name' : this.name,
            'autoassign' : this.autoassign,
            'organisation' : this.organisation_id,
            'eventtype' : this.eventtype_id,
            'users' : this.users ? this.users.map(obj => obj.id) : [] 
        }
    }

    static errors_to_form(errors) {
        return {
            'id' : errors.Id,
            'name' : errors.Name,
            'autoassign' : errors.AutoAssign,
            'organisation' : errors.OrganisationId,
            'eventtype' : errors.EventTypeId,
            'users' : errors.Users
        }
    }

    toString() {
        return this.name;
    }
}


export default Service;
