import { BaseModel }  from './base_model';

class BaseUnavailability extends BaseModel {

    async import_from_api(data) {
        this.id = data.id;
        this.description = data.description;
        this.start = new Date(data.start);
        this.end = new Date(data.end);
        this.timezone = data.timezone;
        this.recurring = data.recurring;
    };

    export_to_api() {
        const data_object = {
            "description" : this.description,
            "start" : this.start,
            "end" : this.end,
            "timezone" : Intl.DateTimeFormat().resolvedOptions().timeZone,
            "recurring" : this.recurring
        }

        if(this.id)
            data_object.id = this.id;

        return data_object;
    }

    async import_from_form(data) {
    }

    export_to_form() {
    }

    toString() {
        return this.description;
    }
}

class BranchUnavailability extends BaseUnavailability {
    async import_from_api(data) {
        super.import_from_api(data);
        this.branch_id = data.branchId;
    }

    export_to_api() {
        var data_object = super.export_to_api();
        data_object.branchId = this.branch_id;
        return data_object;
    }
};

class UserUnavailability extends BaseUnavailability {
    async import_from_api(data) {
        super.import_from_api(data);
        this.user_id = data.userId;
    }

    export_to_api() {
        var data_object = super.export_to_api();
        data_object.userId = this.user_id;
        return data_object;
    }
};

class TemplateUnavailability extends BaseUnavailability {
    async import_from_api(data) {
        super.import_from_api(data);
        this.template_id = data.templateId;
    }

    export_to_api() {
        var data_object = super.export_to_api();
        data_object.templateId = this.template_id;
        return data_object;
    }
};

export { BranchUnavailability, UserUnavailability, TemplateUnavailability };
