import moment from 'moment';

import { BaseModel }  from './base_model';
import { OrganisationApi, EventApi, BranchUnavailabilityApi, ScheduleTemplateApi } from '../api/entities';
import apiFactory from '../api/api_factory';
import { BranchUnavailability } from './unavailability';

class Branch extends BaseModel {
    #event_api = apiFactory.get_instance(EventApi);
    #organisation_api = apiFactory.get_instance(OrganisationApi);
    #schedule_api = apiFactory.get_instance(ScheduleTemplateApi);
    #unavailability_api = apiFactory.get_instance(BranchUnavailabilityApi);


    _get_related_api() {
        return {
            "organisations" : this.#organisation_api,
            "schedules" : this.#schedule_api
        }
    }

    async get_active_options() {
        return {
            'organisations': await this.get_related_options('organisations'),
            'schedules' : (await this.get_related_options('schedules')).filter(x => x.organisation?.id === this.organisation?.id),
        }
    }

    async get_recurring_unavailabilities(start_date, end_date) {
        var unv = [];
        var rec_unv = await this.#unavailability_api.get_objects({
            "branchId" : this.id,
            "Recurring" : true
        });

        // Recurring unavailabilities have correct day of the week and time, but
        // the date is random. We need to change the date part to be representative
        // of the current range
        var date_list = [];
        for(var d = moment(start_date); d < moment(end_date); d.add(1, 'day'))
            date_list.push(d.clone());

        rec_unv.forEach(unv_elem => {
            var unv_start = moment(unv_elem.start);
            var unv_end = moment(unv_elem.end);
            var unv_duration = unv_end.diff(unv_start);

            date_list.forEach(date_elem => {
                if(date_elem.day() === unv_start.day()) {
                    var new_start = moment({
                        year: date_elem.year(),
                        month: date_elem.month(),
                        day: date_elem.date(),
                        hours: unv_start.hours(),
                        minutes: unv_start.minutes(),
                        seconds: unv_start.seconds()
                    });
                    
                    var new_end = new_start.clone().add(unv_duration);

                    var new_unv = new BranchUnavailability();
                    new_unv.id = unv_elem.id;
                    new_unv.user_id = unv_elem.user_id;
                    new_unv.description = unv_elem.description;
                    new_unv.start = new_start.toDate();
                    new_unv.end = new_end.toDate();
                    new_unv.recurring = unv_elem.recurring;

                    unv.push(new_unv);
                }
            });
        });

        return unv;
    }

    async get_unavailabilities(start_date, end_date) {
        var unv = await this.#unavailability_api.get_objects({
            "branchId" : this.id,
            "DateStart" : start_date.toISOString(),
            "DateEnd" : end_date.toISOString()
        });

        return unv;
    }

    async get_template_unavailabilities(start_date, end_date) {
        var res = await Promise.all(this.schedules.map(async sched => sched.get_all_unavailabilities(start_date, end_date)));
        return res.flatMap(subArray => subArray);
    }

    async get_all_unavailabilities(start_date, end_date) {
        var res = await Promise.all([
            this.get_unavailabilities(start_date, end_date),
            this.get_recurring_unavailabilities(start_date, end_date),
            this.get_template_unavailabilities(start_date, end_date)
        ]);

        return res.flatMap(subArray => subArray);
    }

    async get_events(start_date, end_date) {
        return await this.#event_api.get_objects({
            "branchId" : this.id,
            "Cancelled" : false,
            "DateStart" : start_date.toISOString(),
            "DateEnd" : end_date.toISOString()
        });
    }

    async import_from_api(data) {
        this.active = data.active;
        this.id = data.id;
        this.name = data.name;
        this.organisation = await this.get_related_object('organisations', data.organisationId);
        this.address = data.address;
        this.email = data.email;
        this.phonenumber = data.phoneNumber;
        this.longitude = data.longitude;
        this.latitude = data.latitude;
        this.schedules = await Promise.all(data.schedules.map(async obj_id => await this.get_related_object('schedules', obj_id)));
    };


    export_to_api() {
        const data_object = {
            "active" : this.active,
            "name" : this.name,
            "organisationId" : this.organisation?.id,
            "address" : this.address,
            "email" : this.email,
            "phoneNumber" : this.phonenumber,
            "longitude" : this.longitude,
            "latitude" : this.latitude,
            "schedules" : this.schedules.map(obj => obj.id),
        }

        if(this.id)
            data_object.id = this.id;

        return data_object;
    }


    async import_from_form(data) {
        this.active = data.active;
        this.name = data.name;
        if(this.organisation?.id !== data.organisation) {
            this.organisation = await this.get_related_object('organisations', data.organisation);
        }
        this.address = data.address;
        this.email = data.email;
        this.phonenumber = data.phonenumber;
        this.longitude = data.longitude;
        this.latitude = data.latitude;

        if(data.schedules != null) {
            this.schedules = await Promise.all(data.schedules.map(async obj_id => await this.get_related_object('schedules', obj_id)));
        }
        else {
            this.schedules = [];
        }
    }


    export_to_form() {
        return {
            'id' : this.id,
            'active' : this.active,
            'name' : this.name,
            'organisation' : this.organisation?.id,
            'address' : this.address,
            'email' : this.email,
            'phonenumber' : this.phonenumber,
            'longitude' : this.longitude,
            'latitude' : this.latitude,
            'schedules' : this.schedules ? this.schedules.map(obj => obj.id) : []
        }
    }

    static errors_to_form(errors) {
        return {
            'id' : errors.Id,
            'active' : errors.Active,
            'name' : errors.Name,
            'organisation' : errors.OrganisationId,
            'address' : errors.Address,
            'email' : errors.Email,
            'phonenumber' : errors.PhoneNumber,
            'longitude' : errors.Longitude,
            'latitude' : errors.Latitude,
            'schedules' : errors.Schedules
        }
    }

    toString() {
        return this.name;
    }
}

export default Branch;