import moment from 'moment';

import { BaseModel }  from './base_model';
import { OrganisationApi, BranchApi, RoleApi, UserUnavailabilityApi, EventApi } from '../api/entities';
import apiFactory from '../api/api_factory';
import { UserUnavailability } from './unavailability';


class User extends BaseModel {
    #organisation_api = apiFactory.get_instance(OrganisationApi);
    #branch_api = apiFactory.get_instance(BranchApi);
    #role_api = apiFactory.get_instance(RoleApi);
    #event_api = apiFactory.get_instance(EventApi);
    #unavailability_api = apiFactory.get_instance(UserUnavailabilityApi);


    get_permissions() {
        var permissions = [];
        for(var i=0; i<this.roles.length; i++) {
            var role_permissions = this.roles[i].permissions;
            for(var j=0; j<role_permissions.length; j++) {
                if(permissions.includes(role_permissions[j]) !== true)
                    permissions.push(role_permissions[j]);
            }
        }
        return permissions;
    }

    has_permission(permission) {
        if(this.superuser || this.get_permissions().includes(permission))
            return true;
        return false;
    }


    _get_related_api() {
        return {
            "organisations" : this.#organisation_api,
            "branches" : this.#branch_api,
            "roles" : this.#role_api
        }
    }

    async get_active_options() {
        return {
            'organisations': this.superuser ? [] : await this.get_related_options('organisations'),
            'branches': this.superuser ? [] : (await this.get_related_options('branches')).filter(x => x.organisation?.id === this.organisation?.id),
            'roles': this.superuser ? [] : await this.get_related_options('roles')
        }
    }

    async get_user_recurring_unavailabilities(start_date, end_date) {
        var unv = [];
        var rec_unv = await this.#unavailability_api.get_objects({
            "userId" : this.id,
            "Recurring" : true
        });

        // Recurring unavailabilities have correct day of the week and time, but
        // the date is random. We need to change the date part to be representative
        // of the current range
        var date_list = [];
        for(var d = moment(start_date); d < moment(end_date); d.add(1, 'day'))
            date_list.push(d.clone());

        rec_unv.forEach(unv_elem => {
            var unv_start = moment(unv_elem.start);
            var unv_end = moment(unv_elem.end);
            var unv_duration = unv_end.diff(unv_start);

            date_list.forEach(date_elem => {
                if(date_elem.day() === unv_start.day()) {
                    var new_start = moment({
                        year: date_elem.year(),
                        month: date_elem.month(),
                        day: date_elem.date(),
                        hours: unv_start.hours(),
                        minutes: unv_start.minutes(),
                        seconds: unv_start.seconds()
                    });

                    var new_end = new_start.clone().add(unv_duration);

                    var new_unv = new UserUnavailability();
                    new_unv.id = unv_elem.id;
                    new_unv.user_id = unv_elem.user_id;
                    new_unv.description = unv_elem.description;
                    new_unv.start = new_start.toDate();
                    new_unv.end = new_end.toDate();
                    new_unv.recurring = unv_elem.recurring;

                    unv.push(new_unv);
                }
            });
        });

        return unv;
    }

    async get_user_unavailabilities(start_date, end_date) {
        var unv = await this.#unavailability_api.get_objects({
            "userId" : this.id,
            "DateStart" : start_date.toISOString(),
            "DateEnd" : end_date.toISOString()
        });

        return unv;
    }


    async get_branch_unavailabilities(start_date, end_date) {
        return await this.branch ? this.branch?.get_all_unavailabilities(start_date, end_date) : [];
    }

    async get_all_unavailabilities(start_date, end_date) {
        var res = await Promise.all([
            this.get_branch_unavailabilities(start_date, end_date),
            this.get_user_unavailabilities(start_date, end_date),
            this.get_user_recurring_unavailabilities(start_date, end_date)
        ]);

        return res.flatMap(subArray => subArray);
    }


    async get_events(start_date, end_date) {
        return await this.#event_api.get_objects({
            "userId" : this.id,
            "Cancelled" : false,
            "DateStart" : start_date.toISOString(),
            "DateEnd" : end_date.toISOString()
        });
    }


    async import_from_api(data) {
        this.id = data.id;
        this.username = data.username;
        this.firstname = data.firstName;
        this.lastname = data.lastName;
        this.superuser = data.superUser;
        this.email = data.email;
        this.phonenumber = data.phoneNumber;
        this.active = data.active;

        this.role_ids = data.roles;
        this.roles = [];
        for(var i=0; i < this.role_ids.length; i++) {
            var role = await this.get_related_object('roles', this.role_ids[i]);
            this.roles.push(role);
        }

        this.organisation_id = data.organisationId;
        this.organisation = await this.get_related_object('organisations', this.organisation_id);

        this.branch_id = data.branchId;
        this.branch = await this.get_related_object('branches', this.branch_id);
    };

    export_to_api() {
        var data_object = {
            "username" : this.username,
            "firstName" : this.firstname,
            "lastName" : this.lastname,
            "superUser" : this.superuser,
            "active" : this.active,
            "email" : this.email,
            "phoneNumber" : this.phonenumber,
            "organisationId" : this.organisation_id,
            "branchId" : this.branch_id,
            "roles" : this.role_ids,
        };
        if(this.id)
            data_object.id = this.id;
            
        return data_object;
    }

    async import_from_form(data) {
        this.active = data.active;
        this.username = data.username;
        this.firstname = data.firstname;
        this.lastname = data.lastname;
        this.superuser = data.superuser;
        this.email = data.email;
        this.phonenumber = data.phonenumber;
        if(this.organisation_id !== data.organisation) {
            this.organisation_id = data.organisation;
            this.organisation = await this.get_related_object('organisations', this.organisation_id);
        }
        if(this.branch_id !== data.branch) {
            this.branch_id = data.branch;
            this.branch = await this.get_related_object('branches', this.branch_id);
        }

        if(data.roles) {
            this.role_ids = data.roles;
            this.roles = [];
            for(var i=0; i < this.role_ids.length; i++) {
                var role = await this.get_related_object('roles', this.role_ids[i]);
                this.roles.push(role);
            }
        }
        else {
            this.role_ids = [];
            this.roles = [];
        }
    }

    export_to_form() {
        return {
            'id' : this.id,
            'active' : this.active,
            'username' : this.username,
            'firstname' : this.firstname,
            'lastname' : this.lastname,
            'superuser' : this.superuser,
            'email' : this.email,
            'phonenumber' : this.phonenumber,
            'organisation' : this.organisation_id,
            'branch' : this.branch_id,
            'roles' : this.role_ids
        }
    }

    static errors_to_form(errors) {
        return {
            'id' : errors.Id,
            'active' : errors.Active,
            'username' : errors.Username,
            'firstname' : errors.FirstName,
            'lastname' : errors.LastName,
            'superuser' : errors.SuperUser,
            'email' : errors.Email,
            'phonenumber' : errors.PhoneNumber,
            'organisation' : errors.OrganisationId,
            'branch' : errors.BranchId,
            'roles' : errors.Roles
        }
    }

    toString() {
        if(this.firstname && this.lastname && this.firstname !== "" && this.lastname !== "")
        {
            return this.firstname + ' ' + this.lastname;
        }
        else
        {
            return this.username;
        }
            
    }
}


export default User;
