import { api_get, api_post } from './api'

import { BaseEntityApi } from './base_entity'

import {
    Organisation,
    Branch,
    User,
    Role,
    Permission,
    Event,
    EventType,
    Service,
    Guest,
    CommProvider,
    CalendarProvider,
    EventChannel,
    BranchUnavailability,
    UserUnavailability,
    TemplateUnavailability,
    ScheduleTemplate,
    Statistics
} from '../models'


class OrganisationApi extends BaseEntityApi {
    API_PATH = '/organisation'
    MODEL_CLASS = Organisation
}

class BranchApi extends BaseEntityApi {
    API_PATH = '/branch'
    MODEL_CLASS = Branch
}

class UserApi extends BaseEntityApi {
    API_PATH = '/user'
    MODEL_CLASS = User

    async update_password(idx, current_password, new_password) {
        var post_data = {'CurrentPassword' : current_password, 'NewPassword' : new_password};
        await api_post(this.API_PATH + '/' + idx + '/password', null, post_data, true);
    }

    async set_password(idx, new_password) {
        var post_data = {'NewPassword' : new_password};
        await api_post(this.API_PATH + '/' + idx + '/password', null, post_data, true);
    }

    async get_me() {
        var item = await api_get(this.API_PATH + '/me/', null, true);
        return await this.to_model(item);
    }

    async update_my_password(current_password, new_password) {
        await this.update_password('me', current_password, new_password);
    }

    static async reset_password(email) {
        var post_data = {'Email' : email};
        await api_post('/user/resetpassword', null, post_data);
    }

    static async reset_password_confirm(email, token, new_password) {
        var post_data = {'Email' : email, 'Token' : token, 'Password' : new_password};
        await api_post('/user/resetpasswordconfirm', null, post_data);
    }
}

class RoleApi extends BaseEntityApi {
    API_PATH = '/role'
    MODEL_CLASS = Role
}

class PermissionApi extends BaseEntityApi {
    API_PATH = '/permission'
    MODEL_CLASS = Permission
}

class EventApi extends BaseEntityApi {
    API_PATH = '/event'
    MODEL_CLASS = Event

    async changeStatus(idx, status) {
        await api_post(`${this.API_PATH}/${idx}/status`, null, {'Status': status}, true);
    }
}

class EventTypeApi extends BaseEntityApi {
    API_PATH = '/eventtype'
    MODEL_CLASS = EventType
}

class ServiceApi extends BaseEntityApi {
    API_PATH = '/service'
    MODEL_CLASS = Service

    async Book(idx, post_data) {
        await api_post(this.API_PATH + '/' + idx + '/book', null, post_data, true);
    }
}

class GuestApi extends BaseEntityApi {
    API_PATH = '/guest'
    MODEL_CLASS = Guest
}

class CommProviderApi extends BaseEntityApi {
    API_PATH = '/commprovider'
    MODEL_CLASS = CommProvider
}

class CalendarProviderApi extends BaseEntityApi {
    API_PATH = '/calendareventprovider'
    MODEL_CLASS = CalendarProvider
}

class EventChannelApi extends BaseEntityApi {
    API_PATH = '/eventchannel'
    MODEL_CLASS = EventChannel
}

class UserUnavailabilityApi extends BaseEntityApi {
    API_PATH = '/unavailability/user'
    MODEL_CLASS = UserUnavailability
}

class BranchUnavailabilityApi extends BaseEntityApi {
    API_PATH = '/unavailability/branch'
    MODEL_CLASS = BranchUnavailability
}

class TemplateUnavailabilityApi extends BaseEntityApi {
    API_PATH = '/unavailability/template'
    MODEL_CLASS = TemplateUnavailability
}


class ScheduleTemplateApi extends BaseEntityApi {
    API_PATH = '/scheduletemplate'
    MODEL_CLASS = ScheduleTemplate
}

class StatisticsApi {
    async get_statistics(params) {
        var data = await api_get('/statistics/', params, true);
        var model = new Statistics();
        await model.import_from_api(data);
        return model;
    }
}




export {
    OrganisationApi,
    BranchApi,
    UserApi,
    RoleApi,
    PermissionApi,
    EventApi,
    EventTypeApi,
    ServiceApi,
    GuestApi,
    CommProviderApi,
    CalendarProviderApi,
    EventChannelApi,
    BranchUnavailabilityApi,
    UserUnavailabilityApi,
    TemplateUnavailabilityApi,
    ScheduleTemplateApi,
    StatisticsApi
};