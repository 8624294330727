import { BaseModel }  from './base_model';
import { OrganisationApi } from '../api/entities';
import apiFactory from '../api/api_factory';

class Guest extends BaseModel {
    #organisation_api = apiFactory.get_instance(OrganisationApi);

    _get_related_api() {
        return {
            "organisations" : this.#organisation_api
        }
    }

    async get_active_options() {
        return {
            'organisations': await this.get_related_options('organisations')
        }
    }

    async import_from_api(data) {
        this.id = data.id;
        this.first_name = data.firstName;
        this.last_name = data.lastName;
        this.phone_number = data.phoneNumber;
        this.email = data.email;
        this.notes = data.notes;
        this.organisation_id = data.organisationId;
        this.organisation = await this.get_related_object('organisations', this.organisation_id);
        this.tags = data.tags ? data.tags : {};
    };

    export_to_api() {
        var data_object = {
            "firstName" : this.first_name,
            "lastName" : this.last_name,
            "phoneNumber" : this.phone_number,
            "email" : this.email,
            "notes" : this.notes,
            "organisationId" : this.organisation_id,
            "tags" : this.tags
        };
        if(this.id)
            data_object.id = this.id;
            
        return data_object;
    }


    async import_from_form(data) {
        this.name = data.name;
        this.first_name = data.first_name;
        this.last_name = data.last_name;
        this.phone_number = data.phone_number;
        this.email = data.email;
        this.notes = data.notes;
        if(this.organisation_id !== data.organisation) {
            this.organisation_id = data.organisation;
            this.organisation = await this.get_related_object('organisations', this.organisation_id);
        }
    }

    export_to_form() {
        return {
            'id' : this.id,
            'first_name' : this.first_name,
            'last_name' : this.last_name,
            'phone_number' : this.phone_number,
            'email' : this.email,
            'notes' : this.notes,
            'organisation' : this.organisation_id
        }
    }

    static errors_to_form(errors) {
        return {
            'id' : errors.Id,
            'first_name' : errors.FirstName,
            'last_name' : errors.LastName,
            'phone_number' : errors.PhoneNumber,
            'email' : errors.Email,
            'notes' : errors.Notes,
            'organisation' : errors.OrganisationId
        }
    }

    toString() {
        return this.first_name + ' ' + this.last_name;
    }
}

export default Guest;