import { Image, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';

import { authProviderFactory } from '../api/auth';
import { ProfileCircle } from 'iconoir-react';

function NavigationBar(props) {
    const { t } = useTranslation();
    let navigate = useNavigate();

    function sign_out() {
        authProviderFactory.getActiveProvider().logout();
        navigate("/", { replace: true });
    }

    function settings() {
        navigate("/me/settings");
    }

    return (
        <Navbar bg="dark" variant="dark" expand="lg" className="sticky-top">
            <Navbar.Brand className="px-3" href="/">
                <Image src={require('../assets/logo.png')} width="140" height="30" className="d-inline-block align-top" alt="Magenda"/>
            </Navbar.Brand>
            <Nav className="me-auto">
            </Nav>
            <Nav className='pe-3'>
                <NavDropdown title={<><ProfileCircle/><span className='ps-2'>{props.me.username}</span></>}>
                    <NavDropdown.Item className="fontsize" onClick={settings}><Trans i18nKey="pageList.settings">Settings</Trans></NavDropdown.Item>
                    <NavDropdown.Item className="fontsize" onClick={sign_out}><Trans i18nKey="pageList.signout">Sign out</Trans></NavDropdown.Item>
                </NavDropdown>
            </Nav>
        </Navbar>
    )
}

export default NavigationBar;
