import React from 'react'
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { RoleApi } from '../api/entities';
import { AppFormInput } from '../components/';
import BasePageDetail from './base_detail';

function RoleDetail() {
    const { t } = useTranslation();
    const context = useOutletContext();

    return <BasePageDetail
                title={t('pageList.role', {count: 2})}
                subtitle={t('pageList.role', {count: 1})}
                api={RoleApi}
                canChange={context.me.has_permission('Role.Change')}
                canDelete={context.me.has_permission('Role.Delete')}
                appForm={(entity, activeOptions) =>
                    <>
                        <AppFormInput column title={t('pageList.name')} name='name' type='text'
                                      validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={t('pageList.permission', {count: 2})} name='permissions' type='multiselect'
                                      options={activeOptions['permissions'].map(x => ({ "label": x.name, "value": x.name }))}
                                      validator={yup.array().of(yup.string())}/>

                        <AppFormInput column title={t('pageList.roleGrant', {count: 2})} name='rolegrants' type='multiselect'
                                      options={activeOptions['roles'].map(x => ({ "label": x.name, "value": x.name }))}
                                      validator={yup.array().of(yup.string())}/>
                    </>
                }
            />;
}

export default RoleDetail;