import React from 'react'
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { UserApi } from '../api/entities';
import BasePageList from './base_list';


function UserList() {
    const { t } = useTranslation();
    const context = useOutletContext();

    return <BasePageList
                name={t('pageList.user', {count: 2})}
                api={UserApi}
                tableHeaders = {[
                    t('pageList.username'),
                    t('pageList.email'),
                    t('pageList.organisation', {count: 1}),
                    t('pageList.branch', {count: 1}),
                    t('pageList.active'),
                    t('pageList.role', {count: 1})
                ]}
                getTableRow={(row) => ([
                    row.username,
                    row.email,
                    row.organisation?.toString(),
                    row.branch?.toString(),
                    row.active,
                    row.roles.map(r => r.name).join(' / ')
                ])}
                filters={[
                    {
                        type: "select",
                        placeholder : t('pageList.selectOrganisation'),
                        key : 'organisationId',
                        name : 'organisation',
                        options : (ro, fo) => ro['organisations'].map(x => ({ "label": x.toString(), "value": x.id }))
                    },
                    {
                        type: "select",
                        placeholder : t('pageList.selectBranch'),
                        key : 'branchId',
                        name : 'branch',
                        options : (ro, fo) => {
                            var options = [];
                            if(fo['organisationId'] == null)
                                options = ro['branches'];
                            else
                                options = ro['branches'].filter(b => b.organisation?.id === fo["organisationId"]);
                            return options.map(x => ({ "label": x.toString(), "value": x.id }));
                        }
                    },
                    {
                        type: "select",
                        placeholder : t('pageList.selectRole'),
                        key : 'roleId',
                        name : 'role',
                        options : (ro, fo) => ro['roles'].map(x => ({ "label": x.toString(), "value": x.id }))
                    }
                ]}
                canImport={true}
                canExport={true}
                canChange={context.me.has_permission('User.Add')}
            />;
}


export default UserList;
