import { Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import moment from 'moment';

function AppFormInput(props) {
    let inputType;

    if(typeof props.value == 'undefined' && typeof props.default !== 'undefined')
        props.setFieldValue(props.name, props.default);

    switch(props.type) {
        case 'hidden':
            inputType = <input type="hidden" name={props.name} value={props.value} disabled={props.readonly} />
            break;

        case 'check':
            inputType = <Form.Switch size="md" className="fontsize" name={props.name}
                            onChange={props.handleChange} disabled={props.readonly}
                            isInvalid={props.touched && props.error.length !== 0}
                            checked={props.value} />;
            break;

        case 'radio':
            inputType = <Form.Check size="md" className="fontsize" type="radio" name={props.name}
                            onChange={props.handleChange} disabled={props.readonly}
                            isInvalid={props.touched && props.error.length !== 0}
                            checked={props.value} />;
            break;

        case 'select':
            if(props.loadOptions)
            {
                inputType = (
                    <AsyncSelect inputId={props.formName + "-" + props.name} isDisabled={props.readonly}
                        cacheOptions loadOptions={props.loadOptions}
                        className={props.touched && props.error.length !== 0 ? 'is-invalid' : ''}
                        onChange={(option) => props.setFieldValue(props.name, option.value)}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: props.touched && props.error.length !== 0 ? '#dc3545' : '#cccccc',
                            }),
                        }}>
                    </AsyncSelect>
                );
            }
            else
            {
                var empty_options = [{'label' : '----', 'value' : null}].concat(props.options);
                var default_value = null;
    
                if(props.value !== null && props.value !== undefined) {
                    default_value = props.options.find(option => option.value === props.value) || null;
                    if(default_value == null && props.preserve_value === false) {
                        props.setFieldValue(props.name, default_value);
                    }
                }



                inputType = (
                    <Select inputId={props.formName + "-" + props.name} isDisabled={props.readonly}
                        options={empty_options} value={default_value}
                        className={props.touched && props.error.length !== 0 ? 'is-invalid' : ''}
                        onChange={(option) => props.setFieldValue(props.name, option.value)}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: props.touched && props.error.length !== 0 ? '#dc3545' : '#cccccc',
                            }),
                        }}>
                    </Select>
                );
            }
            break;

        case 'multiselect':
            var default_value = null;

            if(props.value !== null && props.value !== undefined) {
                if(props.value.length == 0)
                    default_value = [];
                else {
                    default_value = props.value.map(value => props.options.find(option => option.value === value)).filter((value) => value !== undefined);
                    if(!props.loadOptions && props.preserve_value === false && (default_value == null || default_value.length == 0)) {
                        props.setFieldValue(props.name, []);
                    }
                }
            }


            if(props.loadOptions)
            {
                inputType = (
                    <AsyncSelect inputId={props.formName + "-" + props.name} isMulti={true} isDisabled={props.readonly}
                        defaultOptions cacheOptions loadOptions={props.loadOptions} defaultValue={default_value}
                        className={props.touched && props.error.length !== 0 ? 'is-invalid' : ''}
                        onChange={(option) => props.setFieldValue(props.name, option ? (option).map((item) => item.value) : [])}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: props.touched && props.error.length !== 0 ? '#dc3545' : '#cccccc',
                            }),
                        }}>
                    </AsyncSelect>
                );
            }
            else
            {
                inputType = (
                    <Select inputId={props.formName + "-" + props.name} options={props.options} isDisabled={props.readonly}
                        isMulti={true} value={default_value}
                        className={props.touched && props.error.length !== 0 ? 'is-invalid' : ''}
                        onChange={(option) => props.setFieldValue(props.name, option ? (option).map((item) => item.value) : [])}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: props.touched && props.error.length !== 0 ? '#dc3545' : '#cccccc',
                            }),
                        }}>
                    </Select>
                );
            }
            break;

        case 'password':
            inputType = (
                <Form.Control size="md" className="fontsize" type="password" name={props.name} 
                    onChange={props.handleChange} value={props.value} disabled={props.readonly}
                    isInvalid={props.touched && props.error.length !== 0} />
            );
            break;

        case 'textarea':
            inputType = (
                <Form.Control size="md" className="fontsize" as="textarea" rows={4} name={props.name}
                onChange={props.handleChange} value={props.value} disabled={props.readonly}
                isInvalid={props.touched && props.error.length !== 0} />
            );
            break;

        case 'datetime-local':
            var datetime_text = props.value;
            var min_datetime_text = props.min;
            if(props.value)
                datetime_text = moment(props.value).format('YYYY-MM-DDTHH:mm:ss');
            if(props.min)
                min_datetime_text = moment(props.min).format('YYYY-MM-DDTHH:mm:ss');

            inputType = (
                <Form.Control size="md" className="fontsize" type="datetime-local" name={props.name}
                    min={min_datetime_text}
                    step={props.step}
                    onChange={(d) => props.setFieldValue(props.name, new Date(d.target.value))}
                    value={datetime_text} disabled={props.readonly}
                    isInvalid={props.touched && props.error.length !== 0} />
            );
            break;

        case 'text':
        default:
            inputType = (
                <Form.Control size="md" type="text" className="fontsize" name={props.name} 
                    onChange={props.handleChange} value={props.value} disabled={props.readonly}
                    isInvalid={props.touched && props.error.length !== 0} />
            );
            break;
    }

    if(props.type === 'hidden')
        return inputType;
    else
        return (
            <Form.Group as={Row} className="mb-3" controlId={props.formName + "-" + props.name}>
                <Form.Label column={props.column} sm="3">
                    {props.title} {props.required && <span className="form-asterisk">*</span>}{props.action}
                </Form.Label>
                <Col>
                    {inputType}
                    <Form.Control.Feedback type="invalid">
                        {props.error}
                    </Form.Control.Feedback>
                </Col>
            </Form.Group>
        );
}

export default AppFormInput;