import { BaseModel }  from './base_model';

class Permission extends BaseModel {

    async import_from_api(data) {
        this.name = data.name;
    };

    export_to_api() {
        const data_object = {
            "name" : this.name
        }

        return data_object;
    }

    async import_from_form(data) {
        this.name = data.name;
    }

    export_to_form() {
        return {
            'name' : this.name
        }
    }

    static errors_to_form(errors) {
        return {
            'name' : errors.Name
        }
    }

    toString() {
        return this.name;
    }
}

export default Permission;
