import React from 'react'
import { Button, Table } from 'react-bootstrap';
import { useOutletContext } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import Select from 'react-select';

import { UserApi } from '../api/entities';
import apiFactory from '../api/api_factory';
import { FormSubmitError } from '../components/exceptions';
import { PasswordChangeForm } from '../components';
import { toast } from 'react-toastify';
import { languages, i18n } from '../i18n';

function UserSettings(props) {
    const { t } = useTranslation();
    const context = useOutletContext();
    const me = context.me;

    const [showModal, setShowModal] = React.useState(false);
    const [userApi] = React.useState(apiFactory.get_instance(UserApi));


    const handlePasswordChange = async (values) => {
        var errors = [];

        if(values['new_password'] !== values['new_password_repeat']) {
            errors['general'] = t('pageList.passwordMismatch');
            throw new FormSubmitError(errors);
        }

        try {
            await userApi.update_my_password(values['current_password'], values['new_password']);
            toast.success("Password has been changed successfully");
        }
        catch(err) {
            errors['general'] = err.message;
            throw new FormSubmitError(errors);
        }
    }

    return (
        <>
            {me &&
                <PasswordChangeForm requireCurrentPassword show={showModal}
                                    onSubmit={async (values) => { await handlePasswordChange(values); setShowModal(false); }}
                                    onCancel={() => setShowModal(false)}/>
            }

            <h4><strong><Trans i18nKey="pageList.settings">Settings</Trans></strong></h4>
            <div className='py-4'>
                <Table borderless className='max-table-width'>
                    <tbody>
                        <tr key="username">
                            <td className='align-middle px-0 py-3'><strong><Trans i18nKey="pageList.username">Username</Trans></strong></td>
                            <td className='align-middle px-0 py-3'>{me.username}</td>
                        </tr>
                        <tr key="name">
                            <td className='align-middle px-0 py-3'><strong><Trans i18nKey="pageList.name">Name</Trans></strong></td>
                            <td className='align-middle px-0 py-3'>{me.firstname} {me.lastname}</td>
                        </tr>
                        <tr key="email">
                            <td className='align-middle px-0 py-3'><strong><Trans i18nKey="pageList.email">Email</Trans></strong></td>
                            <td className='align-middle px-0 py-3'>{me.email}</td>
                        </tr>
                        <tr key="phone">
                            <td className='align-middle px-0 py-3'><strong><Trans i18nKey="pageList.phoneNumber">Phone</Trans></strong></td>
                            <td className='align-middle px-0 py-3'>{me.phonenumber}</td>
                        </tr>
                        <tr key="language">
                            <td className='align-middle px-0 py-3'><strong><Trans i18nKey="pageList.language">Language</Trans></strong></td>
                            <td className='align-middle px-0 py-3'>
                                <Select options={languages.map(l => ({ "label": l.name, "value": l.code }))}
                                        onChange={(option) => i18n.changeLanguage(option.value)}
                                        defaultValue={() => {
                                            var lang = languages.find(l => l.code === i18n.language);
                                            return { "label": lang.name, "value": lang.code };
                                        }}
                                        />
                            </td>
                        </tr>
                        <tr key="password">
                            <td className='align-middle px-0 py-3'><strong><Trans i18nKey="pageList.password">Password</Trans></strong></td>
                            <td className='align-middle px-0 py-3'>
                                <Button variant="outline-success" className="fontsize" onClick={() => setShowModal(true)}>
                                    <Trans i18nKey="pageList.changePassword">Change password</Trans>
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default UserSettings;
