import React from 'react'
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { EventApi } from '../api/entities';
import { Event } from '../models';
import { AppFormInput } from '../components/';
import BasePageDetail from './base_detail';
import EventDateValidator from '../components/eventdate_validator';


function EventDetail() {
    const { t } = useTranslation();
    const context = useOutletContext();

    return <BasePageDetail
                title={t('pageList.event', {count: 2})}
                subtitle={t('pageList.event', {count: 1})}
                api={EventApi}
                canChange={context.me.has_permission('Event.Change')}
                canDelete={context.me.has_permission('Event.Delete')}
                appForm={(entity, activeOptions) => 
                    <>
                        <AppFormInput column title='Title' name='title' type='text'
                                      validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title='Booking Number' name='eventNumber' type='text' readonly={true}
                            validator={yup.string().nullable()} />

                        <AppFormInput column title='Description' name='description' type='textarea'
                                      validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title='Start' name='start' type='datetime-local' required={true}
                                      validator={yup.string().nullable().required().test('EventDateValidator', 'Cannot change event to a past date', EventDateValidator)}/>

                        <AppFormInput column title='End' name='end' type='datetime-local' readonly={true} required={true}
                                      validator={yup.string().nullable().required().test('EventDateValidator', 'Cannot change event to a past date', EventDateValidator)}/>

                        <AppFormInput column title='Created on' name='createdon' type='datetime-local' readonly={true}
                                      validator={yup.string().nullable()}/>

                        <AppFormInput column title={t('pageList.service', {count: 1})} name='service' type='select'
                                      options={activeOptions['services'].map(x => ({ "label": x.toString(), "value": x.id }))}
                                      validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={t('pageList.branch', {count: 1})} name='branch' type='select'
                                      options={activeOptions['branches'].map(x => ({ "label": x.name, "value": x.id }))}
                                      validator={yup.string().nullable()}/>

                        <AppFormInput column title={t('pageList.user', {count: 1})} name='user' type='select'
                                      options={activeOptions['users'].map(x => ({ "label": x.toString(), "value": x.id }))}
                                      validator={yup.string().nullable()}/>

                        <AppFormInput column title={t('pageList.channel', {count: 1})} name='channel' type='select'
                                      options={activeOptions['channels'].map(x => ({ "label": x.name, "value": x.id }))}
                                      validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={t('pageList.guest', {count: 2})} name='guests' type='multiselect'
                                      options={activeOptions['guests'].map(x => ({ "label": x.toString(), "value": x.id }))}
                                      validator={yup.array().of(yup.string()).min(1)} required={true}/>

                        <AppFormInput column title={t('pageList.status', {count: 1})} name='status' type='select'
                                      options={Event.get_statuses().map(x => ({ "label": <>{x.icon}&nbsp;{x.name}</>, "value": x.value }))}
                                      validator={yup.string().nullable().required()} required={true}/>
                    </>
                }
            />;
}


export default EventDetail;