import React from 'react'
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ScheduleTemplateApi } from '../api/entities';
import TemplateSchedule from './unv_template_schedule';
import TemplateUnavailabilitySchedule from './unv_template_unavailability';
import { AppFormInput } from '../components';
import BasePageDetail from './base_detail';

function ScheduleTemplateDetail() {
    const { t } = useTranslation();
    const context = useOutletContext();

    return <BasePageDetail
                title={t('pageList.scheduletemplate', {count: 2})}
                subtitle={t('pageList.scheduletemplate', {count: 1})}
                api={ScheduleTemplateApi}
                canChange={context.me.has_permission('ScheduleTemplate.Change')}
                canDelete={context.me.has_permission('ScheduleTemplate.Delete')}
                appForm={(entity, activeOptions) => 
                    <>
                        <AppFormInput column title={t('pageList.name')} name='name' type='text'
                                      validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={t('pageList.organisation', {count : 1})} name='organisation' type='select'
                                      options={activeOptions['organisations'].map(x => ({ "label": x.name, "value": x.id }))}
                                      validator={yup.string().nullable().required()} required={true}/>
                    </>
                }
                tabs={[t('pageList.weeklyPlan'), t('pageList.planExceptions')]}
            >
                <TemplateSchedule />
                <TemplateUnavailabilitySchedule />
            </BasePageDetail>;
}

export default ScheduleTemplateDetail;