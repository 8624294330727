import React from 'react'
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { RoleApi } from '../api/entities';
import BasePageList from './base_list';


function RoleList() {
    const { t } = useTranslation();
    const context = useOutletContext();

    return <BasePageList
                name={t('pageList.role', {count: 2})}
                api={RoleApi}
                tableHeaders = {[t('pageList.name')]}
                getTableRow={(row) => ([row.name])}
                canImport={false}
                canExport={false}
                canChange={context.me.has_permission('Role.Add')}
            />;
}

export default RoleList;
