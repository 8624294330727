import { NotImplementedError } from '../exceptions';

class BaseModel {
    _related_options = [];

    async get_related_object(object_name, object_id) {
        if(object_id == null)
            return null;

        var object_api = this._get_related_api()[object_name];
        return await object_api.get_object(object_id);
    }

    async get_related_options(object_name) {
        var object_api = this._get_related_api()[object_name];
        try {
            return await object_api.get_objects();
        }
        catch(ex) {
            if(ex.name == 'HttpForbiddenError') {
                return [];
            }
        }
    };


    async get_all_related_options() {
        const apis = this._get_related_api();
        const objectNames = Object.keys(apis);
    
        const promises = objectNames.map(objectName => this.get_related_options(objectName));
        const optionsArray = await Promise.all(promises);
    
        const options = {};
        objectNames.forEach((objectName, index) => {
            options[objectName] = optionsArray[index];
        });
    
        return options;
    }

    get_active_options() {
        throw new NotImplementedError();
    };

    _get_related_api() {
        throw new NotImplementedError();
    };

    async import_from_api(data) {
        throw new NotImplementedError();
    };

    export_to_api() {
        throw new NotImplementedError();
    }

    
    async import_from_form(data) {
        throw new NotImplementedError();
    }


    export_to_form() {
        throw new NotImplementedError();
    }


    toString() {
        throw new NotImplementedError();
    }
}


export { BaseModel };