import React from 'react'
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { EventTypeApi } from '../api/entities';
import BasePageList from './base_list';


function EventTypeList() {
    const { t } = useTranslation();
    const context = useOutletContext();

    return <BasePageList
                name={t('pageList.eventtype', {count: 2})}
                api={EventTypeApi}
                tableHeaders = {[t('pageList.name'), t('pageList.duration'), t('pageList.notifyBefore'), t('pageList.organisation', {count: 1})]}
                getTableRow={(row) => ([
                    row.name,
                    `${row.duration.asMinutes()} ${t('pageList.minutes')}`,
                    row.notifyBefore? `${row.notifyBefore.asMinutes()} ${t('pageList.minutes')}` : null,
                    row.organisation?.name
                ])}
                filters={[
                    {
                        type: "select",
                        placeholder : t('pageList.selectOrganisation'),
                        key : 'organisationId',
                        name : 'organisation',
                        options : (ro, fo) => ro['organisations'].map(x => ({ "label": x.toString(), "value": x.id }))
                    }
                ]}
                canImport={true}
                canExport={true}
                canChange={context.me.has_permission('EventType.Add')}
            />;
}

export default EventTypeList;
