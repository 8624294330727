import React from 'react'
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { BranchApi } from '../api/entities';
import BasePageList from './base_list';

function BranchList() {
    const { t } = useTranslation();
    const context = useOutletContext();
    
    return <BasePageList
                name={t('pageList.branch', {count: 2})}
                api={BranchApi}
                tableHeaders = {[t('pageList.name'), t('pageList.organisation', {count: 1}), t('pageList.active')]}
                getTableRow={(row) => ([row.name, row.organisation?.name, row.active])}
                filters={[
                    {
                        type: "select",
                        placeholder : t('pageList.selectOrganisation'),
                        key : 'organisationId',
                        name : 'organisation',
                        options : (ro, fo) => ro['organisations'].map(x => ({ "label": x.toString(), "value": x.id }))
                    }
                ]}
                canImport={true}
                canExport={true}
                canChange={context.me.has_permission('Branch.Add')}
            />;
}


export default BranchList;
