import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { Formik } from 'formik';

import * as yup from 'yup';
import FormikEffect from './FormikEffect';
import AppFormInput from './app_form_input';
import SpinningButton from './spinningButton';
import { lineSplit } from '../helpers';


function AppForm(props) {
    let navigate = useNavigate();
    const [formErrors, setFormErrors] = React.useState([]);

    const formName = "form-" + props.name;


    const onSubmit = async (values, { setSubmitting }) => {
        setFormErrors([]);
        try {
            // Convert empty string on select to null
            findAppFormInputs(props.children).map(input => {
                if(values[input.props.name] === "") {
                    values[input.props.name] = null;
                }
            });
            var casted_values = formSchema().cast(values);
            var new_values = await props.onSubmit(casted_values);
            setSubmitting(false);
            if(props.OnSuccess)
                props.OnSuccess(new_values);
        }
        catch (err) {
            if(err.name === 'FormSubmitError')
                setFormErrors(err.errors);
            else
                throw err;
        }
    }

    const findAppFormInputs = (children) => {
        return React.Children.map(children, child => {
            const appFormInputs = [];

            if (child && child.type && child.type === AppFormInput)
                appFormInputs.push(child);
                
            if(child && child.props && child.props.children)
                appFormInputs.push(...findAppFormInputs(child.props.children));
            
            return appFormInputs;
        });
    };

    const formSchema = () => {
        var appFormInputs = findAppFormInputs(props.children);
        if(!appFormInputs)
            appFormInputs = [];

        var schema = appFormInputs.map((input) => [input.props.name, input.props.validator]);
        return yup.object().shape(Object.fromEntries(schema));
    };


    const enrichChildrenProps = (children, new_props) => {
        return React.Children.map(children, child => {
            if(child && child.props)
            {
                let props;
                if (child.type &&  child.type === AppFormInput)
                {
                    var name = child.props.name;
                    var props_errors = new_props.errors[name] ? [new_props.errors[name]] : [];
                    var form_errors = formErrors[name] ? formErrors[name] : [];
                    props = {
                        'formName' : formName,
                        'setFieldValue' : new_props.setFieldValue,
                        'handleChange' : new_props.handleChange,
                        'readonly' : child.props.readonly ? child.props.readonly : new_props.disabled,
                        'value' : new_props.values[name],
                        'touched' : new_props.touched[name],
                        'error' : [...props_errors, ...form_errors ],
                        'preserve_value' : new_props.preserve_value
                    };
                }
                else
                {
                    props = {};
                }

                if(child.props.children)
                    return React.cloneElement(child, props, enrichChildrenProps(child.props.children, new_props));
                else 
                    return React.cloneElement(child, props);
            }
            else
                return child;
        });
    };

    const onChange = (current_state, next_state) => {
        if(current_state === next_state)
            return;
        if(props.onChange != null) {
            props.onChange(current_state, next_state);
        }
    };


    const ValidateSchema = async (values) => {
        const errors = {};
        try {
            await formSchema().validate(values, { abortEarly: false, context: {initialValues: props.values}});
          } catch (validationErrors) {
            validationErrors.inner.forEach((error) => {
              errors[error.path] = error.message;
            });
          }
      
          return errors;
    };


    return (
        <Formik
            initialValues={props.values}
            // validationSchema={formSchema()}
            validate={ValidateSchema}
            onSubmit={onSubmit}
            context={{ initialValues : props.values }}>

            {({ handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, isValid, isSubmitting, errors, status }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <FormikEffect onChange={onChange}/>

                    {
                        enrichChildrenProps(props.children, {
                            'setFieldValue': setFieldValue, 'values': values, 
                            'touched': touched, 'errors': errors, 'handleChange': handleChange,
                            'disabled' : !props.canChange, 'preserve_value' : props.preserve_values
                            })
                    }

                    { formErrors["general"] &&
                        <Row>
                            <Col className="text-center mb-4">
                                <p className="text-danger">
                                    {lineSplit(formErrors["general"])}
                                </p>
                            </Col>
                        </Row>
                    }
                    <Row>
                        {props.onCancel &&
                            <Col>
                                <div className="d-grid gap-2">
                                    <Button className="fontsize" variant="outline-secondary" disabled={isSubmitting}
                                        onClick={() => {
                                            if(props.backURL)
                                                navigate(props.backURL);
                                            else if(props.onCancel)
                                                props.onCancel();
                                        }}>
                                        <Trans i18nKey="pageList.cancel">Cancel</Trans>
                                    </Button>
                                </div>
                            </Col>
                        }
                        <Col>
                            <div className="d-grid gap-2">
                                <SpinningButton className="fontsize" variant="primary"
                                                type="submit" isSubmitting={isSubmitting}
                                                disabled={!props.canChange}>
                                <Trans i18nKey="pageList.save">Save</Trans>
                                </SpinningButton>
                            </div>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}

export default AppForm;