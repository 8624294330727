import React from 'react';
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

import { ToastContainer } from 'react-toastify';
import { Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { authProviderFactory } from './api/auth';
import Layout from './components/layout';

import {
    Calendar, Login, ForgotPassword, ResetPassword, OidcAuth, UserSettings, Unauthorized,
    UserList, UserDetail,
    RoleList, RoleDetail,
    OrganisationList, OrganisationDetail,
    BranchList, BranchDetail,
    ScheduleTemplateList, ScheduleTemplateDetail,
    ServiceList, ServiceDetail,
    EventList, EventDetail,
    EventTypeList, EventTypeDetail,
    GuestList, GuestDetail,
    CommProviderList, CommProviderDetail,
    CalendarProviderList, CalendarProviderDetail,
    EventChannelList, EventChannelDetail,
    Statistics
} from './pages';

import 'bootstrap/dist/css/bootstrap.min.css';



function RequireAuth({ children }) {
    let location = useLocation();
    var provider = authProviderFactory.getActiveProvider();

    if (!provider.isLoggedIn()) {
        provider.getAuthURL().then(res => window.location.href = res);
    }
    return children;
}

function App() {
    return(
        <>
            <ToastContainer theme="colored" position="bottom-center" transition={Flip}/>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/auth" element={<OidcAuth />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route index element={<Navigate to="/calendar"/>}/>
                <Route path="/" element={<RequireAuth><Layout /></RequireAuth>}>
                    <Route path="me/settings" element={<UserSettings />} />
                    <Route path="calendar" element={<Calendar />} />
                    <Route path="statistics" element={<Statistics/>} />
                    <Route path="events" element={<EventList />} />
                    <Route path="events/:id" element={<EventDetail />} />
                    <Route path="users" element={<UserList />} />
                    <Route path="users/:id" element={<UserDetail />} />
                    <Route path="roles" element={<RoleList />} />
                    <Route path="roles/:id" element={<RoleDetail />} />
                    <Route path="organisations" element={<OrganisationList />} />
                    <Route path="organisations/:id" element={<OrganisationDetail />} />
                    <Route path="branches" element={<BranchList />} />
                    <Route path="branches/:id" element={<BranchDetail />} />
                    <Route path="schedtemplates" element={<ScheduleTemplateList />} />
                    <Route path="schedtemplates/:id" element={<ScheduleTemplateDetail />} />
                    <Route path="services" element={<ServiceList />} />
                    <Route path="services/:id" element={<ServiceDetail />} />
                    <Route path="eventtypes" element={<EventTypeList />} />
                    <Route path="eventtypes/:id" element={<EventTypeDetail />} />
                    <Route path="guests" element={<GuestList />} />
                    <Route path="guests/:id" element={<GuestDetail />} />
                    <Route path="commproviders" element={<CommProviderList />} />
                    <Route path="commproviders/:id" element={<CommProviderDetail />} />
                    <Route path="calendarproviders" element={<CalendarProviderList />} />
                    <Route path="calendarproviders/:id" element={<CalendarProviderDetail />} />
                    <Route path="eventchannels" element={<EventChannelList />} />
                    <Route path="eventchannels/:id" element={<EventChannelDetail />} />
                </Route>
            </Routes>
        </>
  );
}

export default App;
