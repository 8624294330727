import React from 'react'
import { Accordion, Collapse, Nav, Button } from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
import { Building, Calendar, Clock, Group, MultiBubble, Settings, StatsReport } from 'iconoir-react';
import { useTranslation, Trans } from 'react-i18next';


function Submenu (props) {

    return (
        <>
            <Nav.Item>
                <Accordion flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div className='fontsize'>
                                {props.icon && <span className="me-2">{props.icon}</span>}
                                <span className='me-3'>{props.title}</span>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            { props.items.map((item) => (
                                <Nav.Item key={props.key + '-' + item.title} className="d-flex align-items-center">
                                    {item.icon && <span className='ms-3'>{item.icon}</span>}
                                    <LinkContainer to={item.link}>
                                        <Nav.Link className="ps-2 w-100 text-dark">{item.title}</Nav.Link>
                                    </LinkContainer>
                                </Nav.Item>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Nav.Item>
        </>
    )
}

function Sidebar(props) {
    const { t } = useTranslation();

    var usrSubItems = [];

    if(props.me.has_permission("User.View"))
        usrSubItems.push({title:t('pageList.user', {count:2}), link:'/users'});
    if(props.me.has_permission("Role.View"))
        usrSubItems.push({title:t('pageList.role', {count:2}), link:'/roles'});
        

    var orgSubItems = [];

    if(props.me.has_permission("Organisation.View"))
        orgSubItems.push({title:t('pageList.organisation', {count:2}), link:'/organisations'});
    if(props.me.has_permission("Branch.View"))
        orgSubItems.push({title:t('pageList.branch', {count:2}), link:'/branches'});
    if(props.me.has_permission("ScheduleTemplate.View"))
        orgSubItems.push({title:t('pageList.scheduletemplate', {count:2}), link:'/schedtemplates'});


    var appSubItems = [];

    if(props.me.has_permission("Service.View"))
        appSubItems.push({title:t('pageList.service', {count:2}), link:'/services'});
    if(props.me.has_permission("EventType.View"))
        appSubItems.push({title:t('pageList.eventtype', {count:2}), link:'/eventtypes'});
    if(props.me.has_permission("Guest.View"))
        appSubItems.push({title:t('pageList.guest', {count:2}), link:'/guests'});


    var comSubItems = [];

    if(props.me.has_permission("CommProvider.View"))
        comSubItems.push({title:t('pageList.commprovider', {count:2}), link:'/commproviders'});
    if(props.me.has_permission("CalendarEventProvider.View"))
        comSubItems.push({title:t('pageList.calendarprovider', {count:2}), link:'/calendarproviders'});
    if(props.me.has_permission("EventChannel.View"))
        comSubItems.push({title:t('pageList.eventchannel', {count:2}), link:'/eventchannels'});



    return (
        <Nav className="flex-column pt-2 mb-auto">
            
            <Nav.Item className="d-flex align-items-center active sidebar-item">
                <Calendar/>
                <LinkContainer to="/calendar">
                    <Nav.Link className="ps-2 w-100 text-dark"><Trans i18nKey="pageList.calendar" count={1}>Calendar</Trans></Nav.Link>
                </LinkContainer>
            </Nav.Item>

            <Nav.Item className="d-flex align-items-center sidebar-item">
                <Clock/>
                <LinkContainer to="/events">
                    <Nav.Link className="ps-2 w-100 text-dark"><Trans i18nKey="pageList.event" count={2}>Appointments</Trans></Nav.Link>
                </LinkContainer>
            </Nav.Item>

            <Nav.Item className="d-flex align-items-center sidebar-item">
                <StatsReport/>
                <LinkContainer to="/statistics">
                    <Nav.Link className="ps-2 w-100 text-dark"><Trans i18nKey="pageList.statistics" count={2}>Statistics</Trans></Nav.Link>
                </LinkContainer>
            </Nav.Item>

            {usrSubItems.length > 0 &&
                <Submenu
                    icon={<Group/>}
                    name="usermanagement"
                    title={t('pageList.user', {count: 2})}
                    items={usrSubItems}
                />
            }

            {orgSubItems.length > 0 &&
                <Submenu
                    icon={<Building/>}
                    name="orgmanagement"
                    title={t('pageList.organisation', {count: 2})}
                    items={orgSubItems}
                />
            }

            {appSubItems.length > 0 &&
                <Submenu
                    icon={<MultiBubble/>}
                    name="appmanagement"
                    title={t('pageList.event', {count: 2})}
                    items={appSubItems}
                />
            }

            {comSubItems.length > 0 &&
                <Submenu
                    icon={<Settings/>}
                    name="commmanagement"
                    title={t('pageList.configuration', {count: 2})}
                    items={comSubItems}
                />
            }
        </Nav>
    );
};

export default Sidebar;