import React from 'react'
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { GuestApi } from '../api/entities';
import { AppFormInput } from '../components/';
import BasePageDetail from './base_detail';

function GuestDetail() {
    const { t } = useTranslation();
    const context = useOutletContext();

    return <BasePageDetail
                title={t('pageList.guest', {count: 2})}
                subtitle={t('pageList.guest', {count: 1})}
                api={GuestApi}
                canChange={context.me.has_permission('Guest.Change')}
                canDelete={context.me.has_permission('Guest.Delete')}
                appForm={(entity, activeOptions) => 
                    <>
                        <AppFormInput column title={t('pageList.firstName')} name='first_name' type='text'
                                    validator={yup.string().nullable().required('First name is a required field')} required={true}/>

                        <AppFormInput column title={t('pageList.lastName')} name='last_name' type='text'
                                    validator={yup.string().nullable().required('Last name is a required field')} required={true}/>

                        <AppFormInput column title={t('pageList.phoneNumber')} name='phone_number' type='text'
                                    validator={yup.string().nullable()}/>

                        <AppFormInput column title={t('pageList.email')} name='email' type='text'
                                    validator={yup.string().email().nullable()}/>

                        <AppFormInput column title={t('pageList.organisation', {count : 1})} name='organisation' type='select'
                                    options={activeOptions['organisations'].map(x => ({ "label": x.name, "value": x.id }))}
                                    validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={t('pageList.notes')} name='notes' type='textarea'
                                    validator={yup.string().nullable()}/>
                    </>
                }
            />;
}

export default GuestDetail;