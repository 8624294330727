import { api_get, api_post, api_put, api_delete } from './api'

class BaseEntityApi {
    #object_cache = null;
    #cache_filling = false;


    async _wait_cache_unlock() {
        const poll = resolve => {
            if(this.#cache_filling === false) resolve();
            else setTimeout(_ => poll(resolve), 250);
        }
        return new Promise(poll);
    }

    clear_cache() {
        this.#object_cache = null;
    }

    async get_paged_objects(filters, page, page_size=15) {
        if(page != null)
            filters.Page = page;
        if(page_size != null)
            filters.PageSize = page_size;

        var resp = await api_get(this.API_PATH + '/', filters, true);
        resp.data = await Promise.all(
            resp.data.map(async item => await this.to_model(item))
        );

        return resp;
    }

    // has a basic cache system for filterless queries only
    async get_objects(filters) {
        var items = null;

        if(filters == null) {
            
            if(this.#cache_filling === true)
                await this._wait_cache_unlock();

            if(this.#object_cache !== null) {
                items = this.#object_cache;
            }
            else {
                try {
                    this.#cache_filling = true;
                    items = (await api_get(this.API_PATH + '/', filters, true)).data;
                    this.#object_cache = items;
                    this.#cache_filling = false;
                }
                catch(ex) {
                    this.#cache_filling = false;
                    throw ex;
                }
            }
        }
        else {
            items = (await api_get(this.API_PATH + '/', filters, true)).data;
        }

        return await Promise.all(
            items.map(async item => await this.to_model(item))
        );
    }

    async export_objects(filters) {
        return await api_get(this.API_PATH + '/export', filters, true);
    }

    async import_objects(form_data) {
        return await api_post(this.API_PATH + '/import', null, form_data, true, false);
    };

    async get_object(idx) {
        var item = null;

        if(this.#cache_filling === true)
            await this._wait_cache_unlock();

        item = (await this.get_objects()).find(o => o.id == idx);

        if(item != null)
            return item;
        else {
            item = await api_get(this.API_PATH + '/' + idx + '/', null, true);
            return await this.to_model(item);
        }
    }

    async update_object(idx, item) {
        var resp = await api_put(this.API_PATH + '/' + idx + '/', null, this.from_model(item), true);
        return await this.to_model(resp);
    }

    async add_object(item) {
        var resp = await api_post(this.API_PATH + '/', null, this.from_model(item), true);
        return await this.to_model(resp);
    }

    async delete_object(idx) {
        return await api_delete(this.API_PATH + '/' + idx + '/', null, true);
    }

    async to_model(data) {
        var model = new this.MODEL_CLASS();
        await model.import_from_api(data);
        return model;
    }

    from_model(entity) {
        return entity.export_to_api();
    }
}

export { BaseEntityApi };
