import React from 'react'
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { EventApi } from '../api/entities';
import { Event } from '../models';
import BasePageList from './base_list';
import { toLocaleString } from '../helpers';

function EventList() {
    const { t } = useTranslation();
    const context = useOutletContext();
    
    return <BasePageList
                name={t('pageList.event', {count: 2})}
                api={EventApi}
                tableHeaders = {[
                    t('pageList.start'),
                    t('pageList.end'),
                    t('pageList.user', {count: 2}),
                    t('pageList.guest', {count: 2}),
                    t('pageList.service', {count: 1}),
                    t('pageList.status', {count: 1}),
                    t('pageList.cancelled', {count: 1}),
                    t('pageList.eventNumber', { count: 1 })
                ]}
                getTableRow={(row) => ([
                    toLocaleString(row.start),
                    toLocaleString(row.end),
                    row.users.map(r => r.toString()).join(' / '),
                    row.guests.map(r => r.toString()).join(' / '),
                    row.services.map(r => r.toString()).join(' / '),
                    <>{row.get_status()?.icon}&nbsp;{row.get_status()?.name}</>,
                    row.cancelled,
                    row.eventNumber
                ])}
                filters={[
                    {
                        type: "select",
                        placeholder : t('pageList.selectBranch'),
                        key : 'branchId',
                        name : 'branch',
                        options : (ro, fo) => ro['branches'].map(x => ({ "label": x.toString(), "value": x.id }))
                    },
                    {
                        type: "select",
                        placeholder : t('pageList.selectUser'),
                        key : 'userId',
                        name : 'user',
                        options : (ro, fo) => {
                            var options = [];
                            if(fo['branchId'] == null)
                                options = ro['users'];
                            else
                                options = ro['users'].filter(b => b.branch?.id === fo["branchId"]?.value);
                            return options.map(x => ({ "label": x.toString(), "value": x.id }));
                        }
                    },
                    {
                        type: "select",
                        placeholder : t('pageList.status', {count: 1}),
                        key : 'status',
                        name : 'status',
                        options : (ro, fo) => Event.get_statuses().map(x => ({ "label": <>{x.icon}&nbsp;{t(`pageList.${x.name.toLowerCase()}`)}</>, "value": x.value }))
                    },
                    {
                        type: "select",
                        placeholder : t('pageList.cancelled', {count: 1}),
                        key : 'cancelled',
                        name : 'cancelled',
                        options : (ro, fo) => [{"label" : "Yes", "value" : true}, {"label" : "No", "value" : false}]
                    },
                    {
                        type: "datetime-local",
                        placeholder : t('pageList.start', {count: 1}),
                        key : 'datestart',
                        name : 'datestart'
                    },
                    {
                        type: "datetime-local",
                        placeholder : t('pageList.end', {count: 1}),
                        key : 'dateend',
                        name : 'dateend'
                    }
                ]}
                canImport={true}
                canExport={true}
                canChange={context.me.has_permission('Event.Add')}
            />;
}

export default EventList