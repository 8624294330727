import React from 'react'
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { OrganisationApi } from '../api/entities';
import BasePageList from './base_list';

function OrganisationList() {
    const { t } = useTranslation();
    const context = useOutletContext();

    return <BasePageList
                name={t('pageList.organisation', {count: 2})}
                api={OrganisationApi}
                tableHeaders = {[t('pageList.name'), t('pageList.active')]}
                getTableRow={(row) => ([row.name, row.active])}
                canImport={true}
                canExport={true}
                canChange={context.me.has_permission('Organisation.Add')}
            />;
}

export default OrganisationList;
