import React from 'react';
import { UserCalendar } from '../components';
import { useOutletContext } from "react-router-dom";

function Calendar() {
    const context = useOutletContext();
    const me = context.me;

    return (
        <>
            {me && <UserCalendar user={me}/>}
        </>
    );
}

export default Calendar;
