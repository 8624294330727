import React from 'react'
import { Nav } from 'react-bootstrap';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { HubConnectionBuilder, HttpTransportType, HubConnectionState } from '@microsoft/signalr';

import NavigationBar from './navbar'
import Sidebar from './sidebar';

import { UserApi, EventApi } from '../api/entities';
import apiFactory from '../api/api_factory';
import { HttpForbiddenError, HttpUnauthorizedError } from '../api/exceptions';
import { authProviderFactory } from '../api/auth';


function Layout() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const userApi = apiFactory.get_instance(UserApi);

    const [me, setMe] = React.useState(null);

    const [connection, setConnection] = React.useState(null);
    const [notifications, setNotifications] = React.useState([]);

    React.useEffect(() => {
        userApi.get_me()
               .then(x => setMe(x))
               .catch(err => {
                    if (err instanceof HttpForbiddenError ||
                        err instanceof HttpUnauthorizedError) {
                        navigate('/unauthorized');
                    }
                    else {
                        throw err;
                    }
               });
    }, []);

    React.useEffect(() => {
        var provider = authProviderFactory.getActiveProvider();
        const newConnection = new HubConnectionBuilder()
            .withUrl("/api/v1/notifications", {
                accessTokenFactory: () => provider.get_access_token(),
                // transport: HttpTransportType.WebSockets,
              })
            .withAutomaticReconnect()
            .build();


        setConnection(newConnection);
        requestNotificationPermission();
    }, []);

    const startHubConnection = async () => {
        try {
            if(connection.state == HubConnectionState.Connected)
                await connection.stop();
            console.log("Connecting to notifications endpoint");
            await connection.start();
            console.log("Connected to notifications endpoint");
            connection.on("NewEvent", (data) => processNewEvent(data));
        } catch (err) {
            console.error("Notifications endpoint connection error:", err);
            setTimeout(startHubConnection, 5000);
        }
    };

    React.useEffect(() => {
        if (connection) {
            startHubConnection();
        }
      }, [connection]);


    const processNewEvent = async (data) => {
        var event = await (new EventApi()).to_model(data);
        setNotifications([...notifications, {'type': 'NewEvent', 'data' : event}]);
        if(event.cancelled == false)
            showNotification(t('notifications.newAppointmentTitle'),
                             t('notifications.newAppointmentText', {title : event.title, start: event.start}));
        else
            showNotification(t('notifications.cancelAppointmentTitle'),
                             t('notifications.cancelAppointmentText', {title : event.title, start: event.start}));
    };

    const requestNotificationPermission = () => {
        if ('Notification' in window)
        {
            Notification.requestPermission()
                .then(permission => {
                    if (permission === 'granted') {
                        console.log('Notification permission granted.');
                    }
                })
                .catch(error => {
                    console.error(`Error requesting notification permission: ${error}`);
                });
        }
    };

    const showNotification = (title, body) => {
        if ('Notification' in window && Notification.permission === 'granted')
        {
            const notification = new Notification(title, { body: body });
    
            notification.onclick = () => {
                notification.close();
            };
        }
        else {
            console.error('notification not granted');
        }
    };

      
    if(!me)
        return "";
    

    return (
        <>
            <NavigationBar me={me}/>
            <main className='d-flex flex-nowrap'>
                <Nav className='d-flex flex-column flex-shrink-0 pe-3 sidebar overflow-auto' >
                    <Sidebar me={me}/>
                </Nav>

                <div className='d-flex flex-column flex-shrink-1 flex-grow-1 p-3 overflow-auto'>
                    <Outlet context={{me : me, notifications: notifications}}/>
                </div>
            </main>
        </>
    );
};

export default Layout;
