import React from 'react'
import { useLocation, useSearchParams } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import { toast } from 'react-toastify';

import apiFactory from '../api/api_factory';
import { AppTable } from '../components/';


function BasePageList(props) {
    const entityApi = apiFactory.get_instance(props.api);

    const [searchParams, setSearchParams] = useSearchParams();
    const currentParams = Object.fromEntries(searchParams.entries());

    const [entities, setEntities] = React.useState(null);
    const [relatedOptions, setRelatedOptions] = React.useState(null);

    const [filterOptions, setFilterOptions] = React.useState({});
    const [filters, setFilters] = React.useState([]);
    const [search, setSearch] = React.useState(null);

    const [page, setPage] = React.useState(null);
    const [totalPages, setTotalPages] = React.useState(null);
    const [totalItems, setTotalItems] = React.useState(null);
    const [error, setError] = React.useState(null);

    const isInitialRender = React.useRef(true);
    let location = useLocation();

    React.useEffect(() => {
        if(page == null) {
            if(currentParams.page)
                setPage(currentParams.page);
            else
                setPage(1);
        }
    }, []);


    const setQueryPage = (page) => {
        const newParams = { ...currentParams, page: page};
        setSearchParams(newParams);
        setPage(page);
    }


    const setQueryFilters = (filters) => {
        var queryParams = {};
        var currentQueryParams = currentParams;
        for (const filter of filters) {
            if(filter.value !== null && filter.value !== undefined) {
                if (filter.value instanceof Date)
                    queryParams[filter.name] = filter.value.toISOString();
                else
                    queryParams[filter.name] = filter.value;
            }
            else
                delete currentQueryParams[filter.name];
          }
        const newParams = { ...currentQueryParams, ...queryParams};
        setSearchParams(newParams);
        setFilters(filters);
    }


    React.useEffect(() => {
        if(page) {
            refreshData(isInitialRender.current);
            if(isInitialRender.current)
                isInitialRender.current = false;
        }
    }, [filterOptions, page, search]);


    const refreshData = (clear_cache) => {
        setEntities(null);
        setRelatedOptions(null);
        if(clear_cache)
            apiFactory.clear_all_cache();
        getEntities();
        getRelatedEntities();
    };


    const getEntities = () => {
        entityApi.get_paged_objects(getApiFilters(), page)
                 .then(x => {
                    if(x.page !== page)
                        setQueryPage(x.page);

                    setTotalPages(x.total);
                    setTotalItems(x.count);
                    setEntities(x.data);

                    if(x.total < page && x.total > 0)
                        setQueryPage(x.total);
                 })
                 .catch(ex => {
                    console.error(`${ex.name} -> ${ex.url}`);
                    setError(ex);
                 });
    };

    const getRelatedEntities = () => {
        var model = new entityApi.MODEL_CLASS();
        model.get_all_related_options()
             .then(x => setRelatedOptions(x))
             .catch(ex => {
                console.error(`Failed to retrieve related options: ${ex.name} -> ${ex.url}`);
                setRelatedOptions([]);
             });
    };

    const getApiFilters = () => {
        var apiFilters = {};
        for(var key in filterOptions) {
            if(filterOptions[key] !== null && filterOptions[key] !== undefined) {
                apiFilters[key] = filterOptions[key];
            }
        }

        if(search != null)
            apiFilters['search'] = search;

        return apiFilters;
    };

    const handleFilterChange = (name, value) => {
        setFilterOptions(Object.assign({}, filterOptions, {[name] : value}));
    };


    React.useEffect(() => {
        if(filterOptions && relatedOptions && props.filters)
        {
            var new_filters = props.filters.map((filter) => {

                var value = null;
                if(filter.key in filterOptions)
                    value = filterOptions[filter.key];
                else if(filter.name in currentParams)
                    handleFilterChange(filter.key, currentParams[filter.name]);

                if(filter.type === 'select')
                {
                    var options = filter.options(relatedOptions, filterOptions);
    
                    if(value && !options.find(o => o.value == value))
                        handleFilterChange(filter.key, null);
    
                    return {
                        key : filter.key,
                        name : filter.name,
                        type: filter.type,
                        placeholder : filter.placeholder,
                        options : options,
                        value : value,
                        setValue: handleFilterChange
                    };
                }
                else if(filter.type === 'datetime-local')
                {
                    return {
                        key : filter.key,
                        name : filter.name,
                        type: filter.type,
                        value : value,
                        setValue: handleFilterChange
                    }
                }

            });
            setQueryFilters(new_filters);
        }
    }, [filterOptions, relatedOptions]);


    const importData = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            entityApi.import_objects(formData)
                .then(() => {
                    toast.success('File import was successful!');
                    refreshData(false);
                })
                .catch(error => {
                    toast.error(<>File import failed!<br/>{error.message}</>);
                });
        }
    };

    const exportData = () => {
        entityApi.export_objects(getApiFilters())
            .then(file =>
            {
                const dataURL = URL.createObjectURL(file.blob);

                const downloadLink = document.createElement('a');
                downloadLink.href = dataURL;
                downloadLink.download = file.filename;
                downloadLink.click();
        
                URL.revokeObjectURL(dataURL);
            })
            .catch( ex => 
                toast.error(`File export failed! ${ex.message}`)
            );
    };


    return (
        <>
            <h4><strong>{props.name}</strong></h4>
            {!error &&
                <AppTable name="" filters={filters} headers={props.tableHeaders}
                            values={entities ? entities.map((row) => ({id : row.id, data: props.getTableRow(row)})) : []}
                            import={props.canImport && importData} export={props.canExport && exportData} canChange={props.canChange}
                            page={page} totalPages={totalPages} totalItems={totalItems}
                            handlePageChange={p => setQueryPage(p)} handleSearch={text => setSearch(text)}
                            baseURL={location.pathname} 
                            isLoading={entities == null || relatedOptions == null}/>
            }
            {error && <Alert key='danger' variant='danger'>Error: {error.message}</Alert>}
        </>
    )
}

export default BasePageList;