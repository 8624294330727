import React from 'react'
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ServiceApi } from '../api/entities';
import { AppFormInput } from '../components/';
import BasePageDetail from './base_detail';

function ServiceDetail() {
    const { t } = useTranslation();
    const context = useOutletContext();

    return <BasePageDetail
                title={t('pageList.service', {count: 2})}
                subtitle={t('pageList.service', {count: 1})}
                api={ServiceApi}
                canChange={context.me.has_permission('Service.Change')}
                canDelete={context.me.has_permission('Service.Delete')}
                appForm={(entity, activeOptions) => 
                    <>
                        <AppFormInput column title={t('pageList.name')} name='name' type='text'
                                      validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={t('pageList.autoassign', {count : 1})} name='autoassign' type='check'
                                      default={false} validator={yup.bool()} required={true}/>

                        <AppFormInput column title={t('pageList.organisation', {count : 1})} name='organisation' type='select'
                                      options={activeOptions['organisations'].map(x => ({ "label": x.name, "value": x.id }))}
                                      validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={t('pageList.eventtype', {count : 1})} name='eventtype' type='select'
                                      options={activeOptions['eventtypes'].map(x => ({ "label": x.name, "value": x.id }))}
                                      validator={yup.string().nullable().required('Appointment type is a required field')} required={true}/>

                        <AppFormInput column title={t('pageList.user', {count : 2})} name='users' type='multiselect'
                                      options={activeOptions['users'].map(x => ({ "label": x.toString(), "value": x.id }))}
                                      validator={yup.array().of(yup.string())}/>
                    </>
                }
            />;
}

export default ServiceDetail;