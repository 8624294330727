import React from 'react'
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { OrganisationApi } from '../api/entities';
import { AppFormInput } from '../components/';
import BasePageDetail from './base_detail';

function OrganisationDetail() {
    const { t } = useTranslation();
    const context = useOutletContext();

    return <BasePageDetail
                title={t('pageList.organisation', {count: 2})}
                subtitle={t('pageList.organisation', {count: 1})}
                api={OrganisationApi}
                canChange={context.me.has_permission('Organisation.Change')}
                canDelete={context.me.has_permission('Organisation.Delete')}
                appForm={(entity, activeOptions) => 
                    <>
                        <AppFormInput column title={t('pageList.name')} name='name'
                                      type='text' validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={t('pageList.timezone')} name='timezone'
                                      type='text' validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={t('pageList.language')} name='language' type='select'
                                      options={entity.languages.map(x=> ({"label" : x.name, "value" : x.value}))}
                                      validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={t('pageList.commprovider', {count : 2})} name='commproviders' type='multiselect'
                                      options={activeOptions['commproviders'].map(x => ({ "label": x.name, "value": x.id }))}
                                      validator={yup.array().of(yup.string())}/>

                        <AppFormInput column title={t('pageList.calendarprovider', {count : 2})} name='calendarproviders' type='multiselect'
                                      options={activeOptions['calendarproviders'].map(x => ({ "label": x.name, "value": x.id }))}
                                      validator={yup.array().of(yup.string())}/>

                        <AppFormInput column title={t('pageList.eventchannel', {count : 2})} name='eventchannels' type='multiselect'
                                      options={activeOptions['eventchannels'].map(x => ({ "label": x.name, "value": x.id }))}
                                      validator={yup.array().of(yup.string())}/>

                        <AppFormInput column title={t('pageList.active')} name='active' type='check'
                                      default={true} validator={yup.bool()} required={true}/>

                        <AppFormInput column title={t('pageList.description')} name='description'
                                      type='textarea' validator={yup.string().nullable()}/>
                    </>
                }
            />;
}

export default OrganisationDetail;
