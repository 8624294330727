import { BaseModel }  from './base_model';
import { OrganisationApi } from '../api/entities';
import apiFactory from '../api/api_factory';

class CommProvider extends BaseModel {
    #organisation_api = apiFactory.get_instance(OrganisationApi);

    providers = [
        {
            "name" : "Dummy phone provider",
            "value" : "DummyPhoneProvider"
        },
        {
            "name" : "Mstat TMS",
            "value" : "MstatTmsProvider"
        },
        {
            "name" : "MStat Corp",
            "value" : "MStatCorpProvider"
        },
        {
            "name" : "Dummy email provider",
            "value" : "DummyEmailProvider"
        },
        {
            "name" : "SMTP email provider",
            "value" : "SmtpEmailProvider"
        }
    ];
    
    communications_types = [
        {
            "name" : "Email",
            "value" : "Email"
        },
        {
            "name" : "Phone",
            "value" : "Phone"
        }
    ]

    _get_related_api() {
        return {
            "organisations" : this.#organisation_api
        }
    }

    async get_active_options() {
        return {
            'organisations': await this.get_related_options('organisations')
        }
    }

    async import_from_api(data) {
        this.id = data.id;
        this.name = data.name;
        this.type = this.communications_types.find(t => t.value === data.type);
        this.provider = this.providers.find(p => p.value === data.provider);
        this.parameters = JSON.stringify(data.parameters);
    };


    export_to_api() {
        var data_object = {
            "name" : this.name,
            "type" : this.type.value,
            "provider" : this.provider.value,
            "parameters" : JSON.parse(this.parameters)
        };
        if(this.id)
            data_object.id = this.id;

        return data_object;
    }


    async import_from_form(data) {
        this.name = data.name;
        this.type = this.communications_types.find(t => t.value === data.type);
        this.provider = this.providers.find(p => p.value === data.provider);
        this.parameters = data.parameters;
    }


    export_to_form() {
        return {
            'id' : this.id,
            'name' : this.name,
            'type' : this.type?.value,
            'provider' : this.provider?.value,
            'parameters' : this.parameters
        }
    }

    static errors_to_form(errors) {
        return {
            'id' : errors.Id,
            'name' : errors.Name,
            'type' : errors.Type,
            'provider' : errors.Provider,
            'parameters' : errors.Parameters
        }
    }


    toString() {
        return this.name;
    }
}

export default CommProvider;