import { connect } from 'formik';
import { useEffect, useState, useRef } from 'react';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
       ref.current = value;
    });
    return ref.current;
}

function FormikEffect({ onChange, formik: { values } }) {
    const [firstRender, setFirstRender] = useState(true);
    const previousValues = usePrevious(values);

    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
        } else {
            onChange(previousValues, values);
        }
    }, [onChange, values]);

    return null;
}

export default connect(FormikEffect);