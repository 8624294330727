import React from 'react'
import { useOutletContext } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { CommProviderApi } from '../api/entities';
import { AppFormInput } from '../components/';
import JSONValidator from '../components/json_validator';
import BasePageDetail from './base_detail';


function CommProviderDetail() {
    const { t } = useTranslation();
    const context = useOutletContext();

    return <BasePageDetail
                title={t('pageList.commprovider', {count: 2})}
                subtitle={t('pageList.commprovider', {count: 1})}
                api={CommProviderApi}
                canChange={context.me.has_permission('CommProvider.Change')}
                canDelete={context.me.has_permission('CommProvider.Delete')}
                appForm={(entity, activeOptions) => 
                    <>
                        <AppFormInput column title={t('pageList.name')} name='name' type='text'
                                      validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={t('pageList.type')} name='type' type='select'
                                      options={entity.communications_types.map(x=> ({"label" : x.name, "value" : x.value}))}
                                      validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={t('pageList.service', {count: 1})} name='provider' type='select'
                                      options={entity.providers.map(x=> ({"label" : x.name, "value" : x.value}))}
                                      validator={yup.string().nullable().required()} required={true}/>

                        <AppFormInput column title={t('pageList.parameter', {count: 2})} name='parameters' type='textarea' required={true}
                                      validator={yup.string().test('JSONValidator', 'Parameters must be a valid JSON string', JSONValidator).required()}/>
                    </>
                }
            />;
}

export default CommProviderDetail;