import React from 'react';
import { useMemo } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';

import { UserUnavailabilityApi } from '../api/entities';
import apiFactory from '../api/api_factory';
import { lineSplit } from '../helpers';


function UserSchedule(props) {
    const [unavailabilites, setUnavailabilites] = React.useState(null);
    const [unavailabilityApi] = React.useState(()=>new UserUnavailabilityApi());

    let { id } = useParams();

    const DefaultStartDate = new Date(2023, 0, 1);

    React.useEffect(() => {
        apiFactory.clear_all_cache();
        refreshUnavailabilites();
    }, [unavailabilityApi]);
    
    const refreshUnavailabilites = () => {
        unavailabilityApi.get_objects({"userId" : id, "Recurring" : true}).then(e => {
            setUnavailabilites(e);
        });
    }

    const eventClashes = (start, end) => {
        var clashes = unavailabilites.filter(u =>
            (u.start >= start && u.start < end) ||
            (u.end > start && u.end <= end ) ||
            (u.start <= start && u.end >= end ) ||
            (u.start >= start && u.end <= end)
        );
        return (clashes.length > 0);
    }

    const convertUnavailability = (unv) => {
        var converted = {
            id: unv.id,
            title: lineSplit(unv.description),
            start: unv.start,
            end: unv.end
        };
        return converted;
    }

    const handleNewUnavailability = React.useCallback(({ start, end }) => {
        if(eventClashes(start, end))
            return;
        var unv = new unavailabilityApi.MODEL_CLASS();
        unv.start = start;
        unv.end = end;
        unv.user_id = id;
        unv.description = props.entity.toString() + '\nNon working hours';
        unv.recurring = true;
        unavailabilityApi.add_object(unv).then(e => {
            refreshUnavailabilites();
        });
    });

    const handleEditUnavailability = React.useCallback((unavailability) => {
        var unv = unavailabilites.find(u => u.id === unavailability.id);
        unavailabilityApi.delete_object(unv.id).then(u => {
            refreshUnavailabilites();
        });
    }, [unavailabilites]);


    const localizer = momentLocalizer(moment);

    const { defaultDate, formats, views } = useMemo(
        () => ({
          defaultDate: DefaultStartDate,
          formats: {
            dayFormat: (date, culture, localizer) =>
              localizer.format(date, 'dddd', culture),
          },
          views: [Views.WEEK],
        }),
        []
    )

    const eventStyleGetter = (event, start, end, isSelected) => {
        return {
            style : {
                fontSize : '80%',
                backgroundColor: '#00000000',
                backgroundImage: `linear-gradient(45deg, #c5c5c5a0 25%, #d1d1d1a0 25%, #d1d1d1a0 50%, #c5c5c5a0 50%, #c5c5c5a0 75%, #d1d1d1a0 75%)`,
                backgroundSize: '25px 25px',
                borderRadius: '1px',
                borderColor: '#959595',
                display: 'block',
                color: '#333'
            }
        }
    }
    
    return (
        <>
            {unavailabilites && 
                <Calendar
                    localizer={localizer}
                    views={[Views.WEEK]}
                    defaultView={Views.WEEK}
                    formats={ formats }
                    events={unavailabilites.map((x) => convertUnavailability(x))}
                    date={DefaultStartDate}
                    eventPropGetter={eventStyleGetter}
                    startAccessor="start"
                    endAccessor="end"
                    onSelectEvent={handleEditUnavailability}
                    onSelectSlot={handleNewUnavailability}
                    selectable
                    style={{ height: "80vh" }}
                    toolbar={null}
                />
            }
        </>
    )
}

export default UserSchedule;