import React from 'react'
import { useParams, useOutletContext } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import { Button, Col, Row } from 'react-bootstrap';
import * as yup from 'yup';

import { UserApi } from '../api/entities';
import apiFactory from '../api/api_factory';


import { AppFormInput, PasswordChangeForm } from '../components/';
import { FormSubmitError } from '../components/exceptions';
import BasePageDetail from './base_detail';
import UserUnavailabilitySchedule from './user_unavailability';
import UserSchedule from './user_schedule';
import { toast } from 'react-toastify';

import { EnsureNull } from '../helpers';

function UserDetail() {
    const { t } = useTranslation();
    const context = useOutletContext();
    const userApi = apiFactory.get_instance(UserApi);

    const [showModal, setShowModal] = React.useState(false);

    let { id } = useParams();


    const handlePasswordChange = async (values) => {
        var errors = [];

        if(values['new_password'] !== values['new_password_repeat']) {
            errors['general'] = "Passwords do not match";
            throw new FormSubmitError(errors);
        }

        try {
            await userApi.set_password(id, values['new_password']);
            toast.success("Password has been set successfully");
        }
        catch(err) {
            errors['general'] = err.message;
            throw new FormSubmitError(errors);
        }
    }


    return (
    <>
        <PasswordChangeForm show={showModal}
                            onSubmit={async (values) => { await handlePasswordChange(values); setShowModal(false); }}
                            onCancel={() => setShowModal(false)}/>

        <BasePageDetail
                title={t('pageList.user', {count: 2})}
                subtitle={t('pageList.user', {count: 1})}
                api={UserApi}
                canChange={context.me.has_permission('User.Change')}
                canDelete={context.me.has_permission('User.Delete')}
                appForm={(entity, activeOptions) => 
                    <>
                        <AppFormInput column title={t('pageList.username')} name='username'
                                    type='text' validator={yup.string().nullable().required()} required={true}/>
                        <AppFormInput column title={t('pageList.firstName')} name='firstname'
                                    type='text' validator={yup.string().nullable()}/>
                        <AppFormInput column title={t('pageList.lastName')} name='lastname'
                                    type='text' validator={yup.string().nullable()}/>
                        <AppFormInput column title={t('pageList.email')} name='email'
                                    type='text' validator={yup.string().email().nullable().required()} required={true}/>
                        <AppFormInput column title={t('pageList.phoneNumber')} name='phonenumber' type='text'
                                    validator={yup.string().transform(EnsureNull).nullable()}/>
                        <AppFormInput column title={t('pageList.organisation', {count: 1})} name='organisation' type='select'
                                    options={activeOptions['organisations'].map(x => ({ "label": x.name, "value": x.id }))}
                                    validator={yup.string().when('superuser', {is: (val) => val == false, then: yup.string().nullable().required(), otherwise: yup.string().nullable()})} required={true}/>
                        <AppFormInput column title={t('pageList.branch', {count: 1})} name='branch' type='select'
                                    options={activeOptions['branches'].map(x => ({ "label": x.name, "value": x.id }))}
                                    validator={yup.string().nullable()}/>
                        <AppFormInput column title={t('pageList.superuser')} name='superuser'
                                    type='check' default={false} validator={yup.bool()} required={true}/>
                        <AppFormInput column title={t('pageList.role', {count: 2})} name='roles' type='multiselect'
                                    options={activeOptions['roles'].map(x => ({ "label": x.name, "value": x.id }))}
                                    validator={yup.array().of(yup.string())}/>
                        <AppFormInput column title={t('pageList.active')} name='active'
                                    type='check' default={true} validator={yup.bool()} required={true}/>


                        {typeof entity.id !== 'undefined' &&
                            <Row className="mb-3">
                                <Col sm="3">
                                    <Trans i18nKey="pageList.password">Password</Trans>
                                </Col>
                                <Col>
                                    <Button className="fontsize" disabled={!context.me.has_permission('User.PasswordChange')}
                                            variant="outline-success" onClick={() => setShowModal(true)}>
                                        <Trans i18nKey="pageList.setPassword">Set password</Trans>
                                    </Button>
                                </Col>
                            </Row>
                        }

                    </>
                }
                tabs={[t('pageList.weeklyPlan'), t('pageList.planExceptions')]}
            >
                <UserSchedule/>
                <UserUnavailabilitySchedule/>
            </BasePageDetail>
    </>
    )
}

export default UserDetail;