import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useOutletContext } from "react-router-dom";
import { toast } from 'react-toastify';

import { Card, Table } from 'react-bootstrap';
import AppSearchBar from '../components/app_search_bar';
import { AppSpinnerOverlay } from '../components/';
import apiFactory from '../api/api_factory';
import { StatisticsApi, OrganisationApi, BranchApi, ServiceApi, UserApi } from '../api/entities';

import { Event } from '../models'


function Statistics() {
    const { t } = useTranslation();
    const context = useOutletContext();
    const me = context.me;

    const statisticsApi = apiFactory.get_instance(StatisticsApi);
    const organisationApi = apiFactory.get_instance(OrganisationApi);
    const branchApi = apiFactory.get_instance(BranchApi);
    const serviceApi = apiFactory.get_instance(ServiceApi);
    const userApi = apiFactory.get_instance(UserApi);

    const [statistics, setStatistics] = React.useState(null);
    const [objects, setObjects] = React.useState(null);
    const [filters, setFilters] = React.useState(null);
    const [selections, setSelections] = React.useState({'datestart' : new Date(), 'dateend' : new Date(Date.now() + 86400000)});

    const [loading, setLoading] = React.useState(true);

    
    const handleFilterChange = (key, value) => {
        setSelections((prevSelections) => Object.assign({}, prevSelections, {[key] : value}));
    };

    const initialFilters = [
        {
            type: "datetime-local",
            placeholder : t('pageList.start', {count: 1}),
            key : 'datestart',
            name : 'datestart'
        },
        {
            type: "datetime-local",
            placeholder : t('pageList.end', {count: 1}),
            key : 'dateend',
            name : 'dateend'
        },
        {
            type: "select",
            placeholder : t('pageList.selectOrganisation'),
            key : 'organisationId',
            name : 'Organisation',
            options : []
        },
        {
            type: "select",
            placeholder : t('pageList.selectBranch'),
            key : 'branchId',
            name : 'branch',
            options : []
        },
        {
            type: "select",
            placeholder : t('pageList.selectService'),
            key : 'serviceId',
            name : 'service',
            options : []
        },
        {
            type: "select",
            placeholder : t('pageList.selectUser'),
            key : 'userId',
            name : 'user',
            options : []
        }
    ];

    


    const getStatistics = () => {
        var params = {
            'Entity' : 'Event',
            'AggregateBy' : 'Status'
        };

        var totalFilters = 0;

        filters.forEach(filter => {
            if (filter.value !== null && filter.value !== undefined) {
                params[`filters[${totalFilters}].key`] = filter.key;
                if (filter.value instanceof Date)
                    params[`filters[${totalFilters}].value`] = filter.value.toISOString();
                else
                    params[`filters[${totalFilters}].value`] = filter.value;
                totalFilters++;
            }
        });

        statisticsApi.get_statistics(params)
                     .then(x => {
                        setStatistics(x);
                        setLoading(false);
                     })
                     .catch(ex => {
                        console.error(`Failed to fetch statistics: ${ex.name} -> ${ex.url}`);
                        toast.error('Failed to fetch statistics. Please try again later');
                     });
    }



    React.useEffect(() => {
        if(filters != null)
        {
            setLoading(true);
            setStatistics(null);
            getStatistics();
        }

    }, [filters]);


    const setActiveFilterOptions = (local_filters) =>
    {
        var active_orgs = [ ...objects['organisations'] ];
        var active_branches = [ ...objects['branches'] ];
        var active_users = [ ...objects['users'] ];
        var active_services = [ ...objects['services'] ];


        var org_value = selections['organisationId'];
        if (org_value !== undefined && org_value !== null)
        {
            active_branches = active_branches.filter(b => b.organisation.id == org_value);
            active_users = active_users.filter(u => u.organisation?.id == org_value);
            active_services = active_services.filter(s => s.organisation?.id == org_value);
        }

        var branch_value = selections['branchId'];
        if (branch_value !== undefined && branch_value !== null)
        {
            active_users = active_users.filter(u => u.branch?.id == branch_value);
        }


        var service_value = selections['serviceId'];
        if (service_value !== undefined && service_value !== null)
        {
            var sobj = objects['services'].find(s => s.id == service_value);
            active_users = active_users.filter(u => sobj.users && sobj.users.some(su => su.id == u.id));
        }

        setFilters(() =>
            local_filters.map(filter => {
                var entity;
                switch(filter.key)
                {
                    case 'organisationId':
                        entity = active_orgs;
                        break;
                    case 'branchId':
                        entity = active_branches;
                        break;
                    case 'serviceId':
                        entity = active_services;
                        break;
                    case 'userId':
                        entity = active_users;
                        break;
                    default:
                        return { ...filter, setValue: handleFilterChange, value: selections[filter.key] }
                };

                var options = entity.map(o => ({"label" : o.toString(), "value" : o.id}));
                return {
                    ...filter,
                    options: options,
                    setValue: handleFilterChange,
                    value: options.find(o => o.value == selections[filter.key]) !== undefined ? selections[filter.key] : null
                };
            })
        );
    }

    React.useEffect(() => {
        if(filters != null) {
            setActiveFilterOptions(filters);
        }
    }, [selections]);

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const [organisations, branches, services, users] = await Promise.all([
                    organisationApi.get_objects(),
                    branchApi.get_objects(),
                    serviceApi.get_objects(),
                    userApi.get_objects()
                ]);

                setObjects({
                    'organisations' : organisations,
                    'branches' : branches,
                    'services' : services,
                    'users' : users
                });

            } catch (ex) {
                console.error(`Failed to fetch statistics: ${ex.name} -> ${ex.url}`);
                toast.error('Failed to fetch statistics. Please try again later');
            }
        }

        fetchData();

    }, []);

    React.useEffect(() => {
        if(objects != null) {
            setActiveFilterOptions(initialFilters);
        }
    }, [objects]);

    const status_to_name = (value) => {
        return Event.get_statuses().find(s => s.value == value).name;
    }


    return (
        <>
            <h4><strong><Trans i18nKey="pageList.statistics">Statistics</Trans></strong></h4>
            <Card>
                <Card.Header>
                    <div className='d-flex flex-wrap'>
                        <AppSearchBar filters={filters}/>
                    </div>
                </Card.Header>
                <Card.Body>
                    {loading && <AppSpinnerOverlay/>}
                    {!loading && statistics &&
                        <>
                            <Table borderless className='max-table-width'>
                                <tbody>
                                    <tr key="status">
                                        <td className='align-middle px-0 py-1'>
                                            <strong>
                                                <Trans i18nKey="pageList.totalAppointments">Total appointments</Trans>
                                            </strong>
                                        </td>
                                        <td className='align-middle px-0'><strong>{statistics.result.find(r => r.key === 'Total').value}</strong></td>
                                    </tr>
                                    {statistics.result.filter(r => r.key !== 'Total').map((item, index) => (
                                        <tr key={item.key}>
                                            <td className='align-middle px-0 ps-4'>
                                                    {t(`pageList.${status_to_name(item.key).toLowerCase()}`)}
                                            </td>
                                            <td className='align-middle px-0'>{item.value}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    }
                </Card.Body>
                <Card.Footer>
                </Card.Footer>
            </Card>
        </>
    );
}

export default Statistics;
