import React from 'react'
import { Formik } from 'formik';
import { Container, Card, Col, Row, Form, Alert, Image, Button } from 'react-bootstrap';
import { Navigate, useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';
import * as yup from 'yup';
import { getVersionString } from '../helpers'

import { SpinningButton } from '../components';
import { authProviderFactory } from '../api/auth';
import './login.css';


const schema = yup.object().shape({
    username: yup.string().nullable().required(),
    password: yup.string().nullable().required()
});

function Login() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    let location = useLocation();
    let from = location.state?.from?.pathname || "/";

    var provider = authProviderFactory.getActiveProvider();

    function LoginForm() {
        return (
            <Formik
                initialValues={{ username: '', password: '' }}
                validationSchema={schema}
                onSubmit={async (values, { setSubmitting, setStatus }) => {
                    const result = await provider.login(values.username, values.password);
                    if(result)
                        navigate(from, { replace: true });
                    else {
                        setSubmitting(false);
                        setStatus(t('pageLogin.invalidcredentials'));
                    }

                }}
            >
            {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, isSubmitting, errors, status }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="loginFormUser">
                        <Form.Label><Trans i18nKey="pageLogin.username">Username</Trans></Form.Label>
                        <Form.Control className="fontsize" type="text" name="username" onChange={handleChange}
                                      value={values.username} isInvalid={touched.username && !!errors.username}/>
                        <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                    </Form.Group>
    
                    <Form.Group className="mb-3" controlId="loginFormPassword">
                        <Form.Label><Trans i18nKey="pageLogin.password">Password</Trans></Form.Label>
                        <Form.Control className="fontsize" type="password" name="password" onChange={handleChange}
                                      value={values.password} isInvalid={touched.username && !!errors.password}/>
                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </Form.Group>
                    {status && <Alert variant="danger">{status}</Alert>}
                    <div className="d-grid gap-2">
                        <SpinningButton className="fontsize" variant="primary" type="submit" isSubmitting={isSubmitting}>
                            <Trans i18nKey="pageLogin.login">Log in</Trans>
                        </SpinningButton>
                        <p className='text-center px-2'>
                            <Button className="fontsize" variant="link" as={Link} to="/forgotpassword">
                                <Trans i18nKey="pageLogin.forgotpassword">Forgot your password?</Trans>
                            </Button>
                        </p>

                        
                    </div>
                </Form>
            )}
            </Formik>
        )
    }



    let login_page = (
        <Container fluid className="h-100 d-flex flex-column">
            <Row className="flex-grow-1 justify-content-center align-items-center pb-5">
                <Col sm={8} md={6} lg={4} xl={3}>
                    <Card>
                        <Card.Img id="logo" className="py-3 px-5" src={require('../assets/logo.png')} />
                        <Card.Body>
                            <LoginForm />
                            <div className="d-grid gap-2">
                            <Button variant="light" className='mt-5 fontsize'
                                    onClick={async () => {
                                        authProviderFactory.changeActiveProvider('AzureProvider');
                                        window.location.href = await authProviderFactory.getActiveProvider().getAuthURL();
                                    }}
                            >
                                <Image src={require('../assets/azure-ad.png')} alt="Azure AD" fluid className='mx-2' style={{ maxHeight: '20px' }}/>
                                <Trans i18nKey="pageLogin.azurelogin">Sign in with azure AD</Trans>
                            </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="text-muted">{getVersionString()}</Row>
        </Container>
    );

    let redirect_page = (
        <Navigate to={from} replace/>
    );

    return provider.isLoggedIn() ? redirect_page : login_page;
}

export default Login;
